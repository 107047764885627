import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

function QandA() {

    const [submitting, setSubmitting] = useState(false);
    const [question, setQuestion] = useState("");
    const [progress, setProgress] = useState("");
    const [error, setError] = useState("");

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param) || "";
    };

    const [id,setId] = useState( getQueryParam("id") );
    const [data,setData] = useState({});

    useEffect(() => {
        const handleUrlChange = () => {
          setId(getQueryParam("id"));
        };
    
        window.addEventListener("popstate", handleUrlChange);
        return () => window.removeEventListener("popstate", handleUrlChange);
      }, []);

    useEffect(() => {

        setData();

        if (id && id.length > 0) {

            setTimeout(() => {

                const url = `https://labs.tripdatabase.com/api/qanda/${id}/result.json`;
                console.log('loading qanda',url);
                setProgress(`Loading question ${id}...`);


                fetch(url)
                .then(response => response.json())
                    .then(data => {

                        console.log('qanda',data);


                        if (data===undefined) {
                            console.error('Unable to load question',url);
                            setError('Unable to load question');
                            return;
                        }

                        if (data.question) {
                            setQuestion(data.question);
                        }
                        if (data.error) {
                            setError('An error occurred with this question. Please contact us');
                        } else {
                            setProgress('Loaded');
                            setData(data);
                        }
                    })
                    .catch(err => {
                        console.error(url,err);
                        setProgress('An error occurred loading this question. Please contact us');
                    })
                    

            }, 0 );
                    
        }

    }, [id]);

    const reset = () => {
        setId('');
        setQuestion('');
        setProgress('');
        setData({});
        window.history.pushState({}, "Q&A", `?`);
    }

    const submit = () => {

        if (question.trim().length > 10) {
            setId("");
            window.history.pushState({}, "Q&A", `?`);

            setSubmitting(true);
            setProgress("Submitting question...");

            let stateIndex=0;
            const statePhrases = [
                'Analysing question...',
                'Generating search queries...',
                'Fetching source articles...',
                'Ranking source articles...',
                'Extracting evidence...',
                'Extracting more evidence...',
                'Extracting further evidence...',
                'Analysing evidence...',
                'Checking evidence...',
                'Generating answer...',
                'Generating references...',
                'Fast is fine, but accuracy is everything – Wyatt Earp',
                'Speed saves time; accuracy saves lives.',
                'Time is a factor, but quality is the foundation.',
                'Slow and steady wins the race.',
                'Measure twice, cut once.',
                'Haste makes waste.',
                'Good things come to those who wait.',
                'Rome wasn\'t built in a day.',
                'The tortoise beats the hare.',
                'Take your time and do it right.',
            ]

            const interval=setInterval(() => {
                const newProgress=statePhrases[stateIndex++] || 'Still working...'
                setProgress(newProgress); 
            }, 2000 );

            fetch(`https://n65oicvazrglusojlkebgya2q40lrnxp.lambda-url.eu-west-2.on.aws/?question=${encodeURIComponent(question)}`)
                .then(response => response.json())
                .then(data => {
                    console.log('Response',data);

                    setSubmitting(false);

                    if (data.id) {

                        if (data.status === "OK") {
                            setProgress("Question complete");
                            setId(data.id);
                            window.history.pushState({}, data.question, `?id=${data.id}`);
                        } else {
                            setProgress("Error submitting question. Please contact us.");
                        }
                    
                    }
                    
                })
                .catch(err => {
                    console.error(err);
                    setSubmitting(false);
                    setProgress("Error submitting question. Please contact us.");
                })
                .finally(() => {
                    clearInterval(interval);
                    setProgress("");
                });

        }
    }

    if (error) {
        return <main>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="page-content">
                            <h1>Error</h1>
                            <p>{ error }</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    }

    if (!id) {
        return <main>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="page-content">

                            { !data && 
                            
                                <>
                                    <h1>Submit a clinical question:</h1>
                                    <textarea
                                        ref={(el) => el && el.focus()}
                                        id="question"
                                        name="question"
                                        rows="4"
                                        cols="50"
                                        style={{ width: "100%" }}
                                        disabled={submitting}
                                        value={question}
                                        onChange={(e) => setQuestion(e.target.value)}
                                    />
                        
                                    <p>
                                        <button type="button" disabled={submitting} onClick={() => submit()}>Submit question</button>
                                
                                    </p>
                        
                                    <p>{ progress }</p>
                        
                                    <Responsibility />
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </main>
    }

    console.log('data',data);

    if (!data || !data.summary) {
        return <p>Loading...</p>
    }

    return <Answer />

    function Answer() {
        return <main role="main" className="container main py-4">

        <div>
            <Helmet>
                <title>Question : { data.question }</title>
                <meta
                    name="description"
                    content="Question Us : Evidence-based answers for health professionals | Searching sources such as systematic reviews, clinical guidelines and RCTs"
                />
            </Helmet>
            
            <div className="page-content question-template">

                <div className="question-template__header">
                    <p><a href="qanda">Submit a new question...</a></p>
                <h1 className="question-template__question">{ data.question }</h1>
                </div>
                

                
                <div className="question-template__answer">
                    <div className="question-template__answer-content">
                        <h2 className="text-primary">Clinical Bottom Line</h2>
                        <ReactMarkdown components={{
                            strong: () => null, // Hides all <strong> elements
                        }}>
                            {data.summary?.conclusion}</ReactMarkdown>
                
                        
                        <br/>
                        <hr/>
                        <br />

                        <h2 className="text-primary">Answer</h2>
                        <ReactMarkdown components={{
                            h1({ node, inline, className, children, ...props}) {
                                return (
                                  <h2>
                                    {children}
                                  </h2>
                                )
                              }
                        }}>{data.summary?.answer}</ReactMarkdown>
                    </div>

                    <div className="question-template__disclaimer">
                        <h2 className="text-primary">Disclaimer</h2>
                        <p>This clinical Q&A service is intended for use by qualified healthcare professionals and is provided for informational purposes only. It does not constitute medical advice, diagnosis, or treatment.</p>
                        <p>The developers make no representations or warranties about the accuracy, completeness, or suitability of the information provided. Use of this service is at the user's own risk. Users must apply their professional judgement and consult appropriate sources before making clinical decisions.</p>
                        <p>The developers accept no liability for any loss, harm, or damage arising from decisions made or actions taken based on responses generated by this service.</p>
                    </div>

                </div>
                
            </div>

            

        </div>
    </main>
    }

    function Answer2() {

        const [showArticles,setShowArticles]=useState(false);

        if (!data || !data.summary) {
            return <></>
        }

        if (data.summary.evidence && data.articles) {

            data.summary.evidence.forEach( e => {

                const a = data.articles.find( a => a.url === e.url );
                if (a) {
                    e.title = a.title || JSON.stringify(a);
                } else {
                    e.title = e.url;
                }

            });

        }

        return <>

            <p><a href="qanda">Submit a new question...</a></p>

            <h1>{ data.question }</h1>

           { data.summary?.conclusion && 
            <>
                <h2>Clinical Bottom Line:</h2>
                <ReactMarkdown components={{
                    strong: () => null, // Hides all <strong> elements
                }}>
                     {data.summary?.conclusion}</ReactMarkdown>
                </>

            }

            { data?.summary?.answer &&
                <>
                <h2>Answer:</h2>
                <ReactMarkdown>{data.summary?.answer}</ReactMarkdown>
                </>

            }

            <p> (debug only) <button onClick={ () => setShowArticles(!showArticles) }> Show articles ...</button></p>

            { data?.articles && showArticles &&
                <>
                <h3>Articles from Trip Database:</h3>
                <dl>
                    { data.articles.map((article,i) => {
                        return <Article key={i} article={article} index={i+1} />
                    })}
                </dl>
                </>
            }  
                
            </>

    }


}

export default QandA;

function Evidence( { evidence, index } ) {

    return <>
        <dt>{index}. <a href={evidence.url} target="_blank" rel="noreferrer">{evidence.title}</a></dt>
        <dd> <ReactMarkdown>{evidence.citation}</ReactMarkdown></dd>
    </>

}

function Article( { article, index } ) {

    const [showDD,setShowDD]=useState(false);

    return <>
        <dt>{ index }.{' '}<a href={article.url} target="_blank" rel="noreferrer">{article.title}</a>
            <br />
            
        </dt>
        <dd>
            { <a style={ { cursor:'pointer' }} onClick={ () => setShowDD(!showDD)}> { showDD ? '▶':'▼' } </a> }
            { showDD && <blockquote><ReactMarkdown>{article.evidence}</ReactMarkdown></blockquote> }
        </dd>
        
        </>
}

function Responsibility() {

    return <div>
        <h2>Your Responsibility:</h2>

        <p>The information provided by this automated clinical Q&A service is generated using large language models (LLMs), trained on a wide range of biomedical literature and clinical guidelines. While every effort has been made to ensure the accuracy and relevance of the content, users must be aware that LLMs have limitations and may occasionally produce incorrect or misleading information.</p>

        <p>This service does not provide medical advice, diagnosis, or treatment. It is designed to support healthcare professionals by offering rapid access to evidence-informed responses but is not a substitute for professional clinical judgement.</p>

        <p>Users are responsible for critically evaluating the information, verifying it against trusted sources, and applying it in the context of the individual patient’s clinical situation, preferences, and needs. It remains the responsibility of healthcare professionals to make appropriate decisions in consultation with patients and/or their carers or guardians.</p>

        <p>By using this service, you acknowledge these limitations and accept full responsibility for how you interpret and act upon the information provided.</p>
    </div>

}

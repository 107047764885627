import React from "react";
// import axios from "axios";
// import NHSSvg from "../assets/svg/NHSLogo";
// const store = require("store");
// const _ = require("underscore");

const GuidlinesNoGraphics = () => {
  return (
    <div className="trip-score">
      <span className="trip-score--title">Trip Score</span>
      <div className="trip-score--gauge">
        <span>Not Yet Assessed</span>{" "}
      </div>
    </div>
  );
};
export default GuidlinesNoGraphics;

import React, { useState, useEffect } from "react";
import _ from "underscore";
import { useAuth } from "./../context/auth";
import { Link } from "react-router-dom";
import Search from "./search/Search";
import Logosvg from "./assets/svg/Logo";
import LogoProsvg from "./assets/svg/LogoPro";
// import Navbarnetworksvg from "./assets/svg/Navbarnetwork";
import { checkTokensStorage } from "./../context/checkTokens";
import { isMobile } from "react-device-detect";
import { userBanner } from "./../context/userBanner";
import LoginMdl from "./LoginMdl";
import SignupMdl from "./SignupMdl";
import ErrorMdl from "./../components/errorMdl";
import MainNavigation from './navigation/MainNavigation';

// import { handleLoginRemember } from "./../context/rememberMe";

const axios = require("axios");
const store = require("store");

const NavigationBar = (props) => {
  // Remove any unwanted iframes on component mount
  useEffect(() => {
    const unwantedIframes = document.querySelectorAll('iframe[style*="position: fixed"]');
    unwantedIframes.forEach(iframe => iframe.remove());
  }, []);

  const [loginshow, setLoginShow] = useState(false);
  const [signupshow, setSignupShow] = useState(false);
  const [proshow, setProShow] = useState(false);
  const [hasLogin, setNothing] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  // const [fullname, setFullname] = useState("");
  // const [isLogin, setLogin] = useState(false);
  // const [isPro, setProUser] = useState(false);
  // const { setAuthTokens, authTokens } = useAuth();
  const [toggleMobile, setMobile] = useState(true);

  var loginObj = checkTokensStorage();
  /**
   * handle Mobile
   */
  function handleMobile() {
    if (document.body.classList.contains("admin-page"))
      document.body.classList.remove("admin-page");
    else document.body.classList.add("admin-page");
    if (document.body.classList.contains("mobile-off-canvas--is-visible")) {
      document.body.classList.remove("mobile-off-canvas--is-visible");
    } else {
      document.body.classList.add("mobile-off-canvas--is-visible");
    }
  }
  let componentMounted = true; // (3) component is mounted
  useEffect(() => {
    if (componentMounted) {
      if (isMobile) document.body.classList.add("hide-account-btn");
    }
    return () => {
      // This code runs when component is unmounted
      componentMounted = false; // (4) set it to false if we leave the page
    };
  }, []);

  userBanner(props.isPro);
  let active_link = !_.isUndefined(window.location.pathname)
    ? window.location.pathname
    : "";
  let want_url = window.location.pathname + window.location.search;

  const handleClose = () => {
    // Close any open modals or menus
    setLoginShow(false);
    setSignupShow(false);
    setErrorShow(false);
    if (document.body.classList.contains("mobile-off-canvas--is-visible")) {
      document.body.classList.remove("mobile-off-canvas--is-visible");
    }
  };

  return (
    <nav className="navbar-container mb-0">
      {/* <div id="navbar-bg" className="navbar--bg" style={{ opacity: "0.3" }} /> */}

      <LoginMdl
        props={props}
        setLoginShow={setLoginShow}
        setSignupShow={setSignupShow}
        setErrorShow={setErrorShow}
        setErrors={setErrors}
        show={loginshow}
        want_url={want_url}
      />
      <SignupMdl
        props={props}
        setLoginShow={setLoginShow}
        setSignupShow={setSignupShow}
        setErrorShow={setErrorShow}
        setErrors={setErrors}
        show={signupshow}
      />
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <div className="navbar">
        <Link className="navbar--brand" to="/Home">
          <div className="navbar--brand__free">
            <Logosvg />
          </div>
          <div className="navbar--brand__pro">
            <LogoProsvg />
          </div>
        </Link>

        <MainNavigation active_link={active_link} props={props} />

        <div className="desktop-only">
          {props.isLoggedin ? (
            <ul className="navbar-nav navbar--desktop-admin">
              <li className="nav-item">
                <Link
                  className={
                    active_link === "/Profile"
                      ? "nav-link btn btn-primary"
                      : "nav-link"
                  }
                  to="/Profile"
                  title="My Account"
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  <i className="fas fa-user-circle"></i>
                  &nbsp; My Account
                </Link>
              </li>
            </ul>
          ) : (
            <ul className="navbar-nav navbar--desktop-admin">
              <li className="nav-item">
                <Link
                  id="nav-login"
                  className="nav-link"
                  to="/Login"
                  title="Login"
                  onClick={(e) => {
                    setErrors("");
                    setLoginShow(!loginshow);
                    setSignupShow(false);
                    e.preventDefault();
                  }}
                >
                  {loginObj.chkInstitution.oa_login === true
                    ? "Trip Login"
                    : "Login"}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  id="nav-signup"
                  className="nav-link btn btn-primary"
                  to="/Registration"
                  title="Sign up"
                  onClick={(e) => {
                    setErrors("");
                    setSignupShow(!signupshow);
                    setLoginShow(false);
                    e.preventDefault();
                  }}
                >
                  Sign Up
                </Link>
              </li>
            </ul>
          )}
        </div>

        <div className="mobile-menu-toggle">
          <button
            className="navbar-btn js-menu-toggle btn btn-primary navbar-dark"
            type="button"
            onClick={(e) => {
              setMobile(!toggleMobile);
              handleMobile();
            }}
          >
            <span className="navbar-toggler-icon"></span>
            Menu
          </button>
        </div>
      </div>

      <div id="navbar--search" className="navbar--search">
        <div className="navbar--search--homepage-intro">
          <h1>
            <span className="bold">Trip medical database</span>
            <br></br>A smart, fast tool to find high quality clinical research
            evidence
          </h1>
        </div>
        <Search
          {...props}
          isPro={!_.isUndefined(props.isPro) ? props.isPro : false}
        />
      </div>
    </nav>
  );
};

export default NavigationBar;

import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import _ from "underscore";
/**
 * Extracts the summary and the remaining text from the given text blob.
 * @param {string} text - The input text blob.
 * @returns {object} - An object containing the summary and the remaining text.
 */
function extractSummaryAndElements(text) {
  const summaryPattern =
    /Summary\s*(<b>|<strong>)?([\s\S]*?)(<\/b>|<\/strong>)?\s*$/i;
  const summaryMatch = text.match(summaryPattern);

  const summary = summaryMatch ? summaryMatch[2].trim() : "";
  const remainingText = summaryMatch
    ? text.replace(summaryPattern, "").trim()
    : text.trim();

  return { summary, remainingText };
}
function convertMarkdownToHtml(text) {
  // Replace ### with <h3> tags
  text = text.replace(/^###\s*(.+)$/gm, "<strong>$1</strong>");

  // Replace ** with <b> tags
  text = text.replace(/\*\*(.+?)\*\*/g, "<b>$1</b>");

  // Replace \n with <br> tags
  text = text.replace(/\n/g, "<br>");

  return text;
}
const RobMdl = ({ handleClose, show, id }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      centered
    >
      <Form width="100%">
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-info-circle"></i> Robot Reviewer Score
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            We have worked with RobotReviewer{" "}
            <a href="https://www.robotreviewer.net/" target="_blank">
              https://www.robotreviewer.net/{" "}
            </a>
            to automatically estimate the quality of clinical trials. <br></br>
          </p>
          <p>
            In using this score it is important to understand the process, so we
            recommend you read the explainer{" "}
            <a
              href="https://blog.tripdatabase.com/2023/05/17/introducing-our-rct-score/"
              target="_blank"
            >
              {" "}
              'Introducing our RCT score'
            </a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default RobMdl;

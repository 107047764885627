import React from "react";
import { Card, Table, Row, Col } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import axios from "axios";

const _ = require("underscore");

const promiseOptions = (inputValue) => {
  return axios
    .get(
      !_.isUndefined(process.env.REACT_APP_API_URL)
        ? process.env.REACT_APP_API_URL +
            "/Administrator/getInstitutionUsers?search=" +
            inputValue
        : "/Administrator/getInstitutionUsers?search=" + inputValue,
      {
        withCredentials: true,
      }
    )

    .then((res) => {
      if (res.status === 200) {
        // setTemplateSelectData(res.data.data);
        return res.data.users;
      } else {
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const PaymentExpandedComponent = ({
  data,
  setEmail,
  setInstid,
  handleUserAdd,
  handleAdmin,
  handleUserRemove,
}) => {
  return (
    <Card variant="info" border="success">
      <Card.Body>
        <Card.Header className="text text-success">
          <h4>{data.institution_name}</h4>
        </Card.Header>

        <Row className="mt-2">
          <Col>
            {!_.isEmpty(data.payments) ? (
              <Table>
                <thead>
                  <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Reminder Date</th>
                    <th>Amount</th>
                    <th>Paid</th>
                    <th>Active</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {data.payments.map((transaction, idx) => (
                    <tr key={idx}>
                      <td>{transaction.date_from}</td>
                      <td>{transaction.date_to}</td>
                      <td>{transaction.date_reminded}</td>
                      <td>{transaction.amount}</td>
                      <td>{transaction.paid}</td>
                      <td>
                        {transaction.active === 1 ? (
                          <i className="fa fa-check-circle text-success"></i>
                        ) : (
                          <i className="fa fa-times-circle text-danger"></i>
                        )}
                      </td>
                      <td>{transaction.notes}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <h5 className="text-warning">No Payment Added</h5>
            )}
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            {!_.isEmpty(data.users) ? (
              <Table>
                <thead>
                  <tr>
                    <th>email</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Admin</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.users.map((user, i) => (
                    <tr key={i + "user"}>
                      <td>{user.email}</td>
                      <td>{user.firstname}</td>
                      <td>{user.lastname}</td>
                      <td>
                        {user.isadmin ? (
                          <i
                            className="fa fa-toggle-on text-success"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              handleAdmin(e, {
                                user_id: user.user_id,
                                institution_id: data.id,
                                is_admin: 0,
                              })
                            }
                          ></i>
                        ) : (
                          <i
                            className="fa fa-toggle-off"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              handleAdmin(e, {
                                user_id: user.user_id,
                                institution_id: data.id,
                                is_admin: 1,
                              })
                            }
                          ></i>
                        )}
                      </td>
                      <td>
                        <i
                          className="fa fa-trash text-danger"
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            handleUserRemove(e, {
                              user_id: user.user_id,
                              institution_id: data.id,
                            })
                          }
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <h5 className="text-warning">No Users Added</h5>
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={promiseOptions}
              //value={batch.currenttemplate ? batch.currenttemplate : ""}
              onChange={
                (selectedOptions) => {
                  if (selectedOptions.value) {
                    setEmail(selectedOptions.value);
                    setInstid(data.id);
                  }
                }
                //handleTemplateSelect(selectedOptions)
              }
            />
          </Col>
          <Col>
            <button
              className="btn btn-info all-round"
              institutionid={data.id}
              onClick={handleUserAdd}
            >
              <i className="fa fa-plus"></i>
            </button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default PaymentExpandedComponent;

import React from 'react';
import { Modal } from 'react-bootstrap';
import Key from '../../pages/Key';

const KeyModal = ({ show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Results Page Key</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Key />
            </Modal.Body>
        </Modal>
    );
};

export default KeyModal;
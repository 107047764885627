import React, { useState, useEffect } from "react";
import _ from "underscore";
import { Row, Col, Form, Button, Card, Alert } from "react-bootstrap";
import PasswordStrengthBar from "react-password-strength";
import Sidebar from "../components/SidebarRegistration";
import ErrorMdl from "../components/errorMdl";
import {
  setQueryStringValue,
  getQueryStringValue,
} from "../components/search/queryString";
const axios = require("axios");

const UpdateToken = (props) => {
  const [hasError, setErrors] = useState(true);
  const [errorshow, setErrorShow] = useState(false);
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [passkey, setPasskey] = useState("");
  const [passValid, setPassValid] = useState(false);
  const [passMatch, setHideMatch] = useState(true);
  const [showPasswordtxt, setPasswordtxt] = useState(true);

  async function fetchCheck() {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/User/getCheck"
          : "/User/getCheck",
        {
          params: { key: getQueryStringValue("_t") },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.key) {
            setPasskey(res.data.key);
          } else {
            setErrors({ message: res.data.message });
            setPasswordtxt(false);
            setErrorShow(true);
          }
        } else {
          const error = new Error(res.message);
          setErrors({ message: res.message });
          setErrorShow(true);
          setPasswordtxt(false);
        }
      })
      .catch((error) => {
        if (!_.isEmpty(error)) {
          setErrors(error);
          setErrorShow(true);
          setPasswordtxt(false);
        }
      });
  }

  useEffect(() => {
    fetchCheck();
  }, [passkey, passMatch, passValid]);

  async function sendUpdate() {
    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/User/UpdateToken"
          : "/User/UpdateToken",
        {
          key: passkey,
          passkey: pass2,
        }
      )
      .then((res) => {
        if (res) {
          if (!_.isUndefined(res.status) && res.status === 200) {
            props.history.push({
              pathname: "/Home",
              state: {
                withCredentials: true,
              },
            });
          } else {
            //todo setError;
            setErrors(res.Error);
            setErrorShow(true);
          }
        } else {
          // const error = new Error(res.error);
          setErrors({ message: "Password mismatched!" });
          setErrorShow(true);
        }
        //callback for the response
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
      });
  }
  const updateToken = (event) => {
    if (passValid === false) {
      if (!hasError) setErrors({ message: "Invalid Password" });
    }

    if (hasError) setErrorShow(true);
    else {
      setErrorShow(false);
      sendUpdate();
    }

    event.preventDefault();
  };
  const handlePass1 = (params) => {
    setPass1(params.password);
    setPassValid(params.isValid);
    if (params.isValid !== true || pass2 !== params.password)
      setErrors({ message: "Invalid Password" });
    else {
      setErrors(false);
    }
  };

  const handlePass2 = (params) => {
    setPass2(params.password);
    setPassValid(params.isValid);
    setErrors(params.isValid);

    if (pass1 === params.password && pass1.length >= 6) {
      setHideMatch(true);
    } else setHideMatch(false);

    // if (passMatch === true) {
    if (pass1 !== params.password || pass1.length < 6) {
      setPassValid(false);
      setErrors({ message: "Password mismatched !" });
    } else {
      setPassValid(params.isValid);
      setErrors(false);
    }
  };

  return (
    <main role="main" className="main mt-2 container-fluid">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <Row></Row>
      <Row>
        <Col md="3">
          <Sidebar {...props} />
        </Col>
        <Col md="9">
          <Row>
            <Col>
              {showPasswordtxt && (
                <Card variant="info">
                  <Card.Body>
                    <Card.Header>
                      <h2>Update Password!</h2>
                    </Card.Header>
                    <Form onSubmit={updateToken}>
                      <Form.Group controlId="pass1">
                        <Form.Label>
                          <i className="fa fa-lock"></i> Enter New Password
                        </Form.Label>

                        <PasswordStrengthBar
                          isRequired={true}
                          password={pass1}
                          minLength={5}
                          minScore={2}
                          changeCallback={handlePass1}
                          inputProps={{
                            name: "password1",
                            autoComplete: "off",
                            required: true,
                            className: "form-control",
                          }}
                        />
                      </Form.Group>
                      <Form.Group controlId="pass2">
                        <Form.Label>
                          <i className="fa fa-lock"></i> Re-type New Password
                        </Form.Label>

                        <PasswordStrengthBar
                          isRequired={true}
                          password={pass2}
                          minLength={5}
                          minScore={2}
                          changeCallback={handlePass2}
                          inputProps={{
                            name: "password2",
                            autoComplete: "off",
                            required: true,
                            className: "form-control",
                          }}
                        />
                      </Form.Group>
                      <Alert variant="info">
                        You must specify a password of{" "}
                        <b>minimum 6 characters.</b> You can choose any
                        combination and the password strength meter is for
                        information only.{" "}
                      </Alert>
                      <Alert variant="warning" hidden={passMatch}>
                        Password Mismatched
                      </Alert>

                      <Button
                        className="btn btn-success btn-lg pull-right"
                        type="submit"
                        disabled={hasError}
                      >
                        Update Password <i className="fa fa-sign-in"></i>
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default UpdateToken;

import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import axios from "axios";
import _ from "underscore";

const SynonymsMdl = ({
  getParamsValues,
  criteria,
  show,
  setShowSynonymsModal,
}) => {
  const [synonymsfound, setSynonymsFound] = useState([]);
  const [reporterEmail, setreporterEmail] = useState("");
  const [synonymsSuggestion, setSynonymsSuggestion] = useState("");
  const [showForm, setShowForm] = useState(false);

  let foundItems = [];
  const fetchSynonyms = async (keywords) => {
    let synonyms_API =
      "https://yxvv1rc7ye.execute-api.eu-west-2.amazonaws.com/dev/search?searchTerm=$SEARCH_TERM".replace(
        "$SEARCH_TERM",
        keywords
      );

    axios
      .get(synonyms_API)
      .then(async (res) => {
        let data = null;
        if (res.status === 200) {
          if (!_.isUndefined(res.data)) {
            if (
              !_.isUndefined(res.data.matches) &&
              !_.isEmpty(res.data.matches)
            ) {
              // Process all matches and their values into individual terms
              let allTerms = [];
              res.data.matches.forEach((obj) => {
                for (const key in obj) {
                  const terms = obj[key].split(",").map((term) => term.trim());
                  allTerms.push(...terms);
                }
              });

              // Debug logs
              // console.log('Original search term:', getParamsValues());
              // console.log('All terms before filtering:', allTerms);

              // Remove duplicates, empty strings, and the original search term
              const searchTerm = getParamsValues().toLowerCase().trim();
              allTerms = [...new Set(allTerms)].filter((term) => {
                const termLower = term.toLowerCase().trim();
                // console.log('Comparing:', termLower, 'with:', searchTerm, 'equal?:', termLower === searchTerm);
                return term !== "" && termLower !== searchTerm;
              });

              // console.log('Filtered terms:', allTerms);
              setSynonymsFound(allTerms.length > 0 ? allTerms : ["None"]);
            }
          }
        }
        return true;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  };
  const sendsynonymSuggestions = async (e) => {
    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/User/synonyms"
          : "/User/synonyms",
        {
          reporterEmail: reporterEmail,
          synonyms: synonymsSuggestion,
          search: criteria.criteria,
        }
        // { withCredentials: true }
      )

      .then(async (res) => {
        if (res.status === 200) {
        }
      })
      .catch((error) => {
        return null;
      });
    e.preventDefault();
    setShowSynonymsModal(false);
  };
  useEffect(() => {
    if (!_.isUndefined(criteria.criteria)) {
      fetchSynonyms(criteria.criteria);
    }
  }, [criteria]);
  return (
    <Modal size="lg" show={show} onHide={() => setShowSynonymsModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Synonyms on Trip</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          When you search for a term, we automatically include certain synonyms
          for you to improve your results.
        </p>
        <div className="table-responsive border rounded">
          <table className="table table- mb-0">
            <tbody>
              <tr>
                <td className="text-center" style={{ minWidth: "200px" }}>
                  <p className="mb-0 bold">Your search terms</p>
                </td>
                <td
                  className="text-center border-start"
                  style={{ minWidth: "200px" }}
                >
                  <p className="mb-0 bold">Included synonyms</p>
                </td>
              </tr>
              <tr>
                <td className="text-center" style={{ minWidth: "200px" }}>
                  <span className="bold text-navy">{getParamsValues()}</span>
                </td>
                <td
                  className="text-center border-start"
                  style={{ minWidth: "200px" }}
                >
                  <ul className="list-unstyled d-flex flex-wrap justify-content-center gap-2 mt-0 mb-0">
                    {synonymsfound.map((term, idx) => (
                      <li key={idx}>
                        <span className="badge badge-pill border-0">
                          {!_.isUndefined(term) ? term : ""}
                        </span>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-4">
          {!showForm && (
            <a 
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowForm(true);
              }}
              className="text-primary text-decoration-underline"
            >
              Improve These Synonyms
            </a>
          )}
        </div>

        {showForm && (
          <div className="mt-4">
            <Form>
              <Form.Group>
                <Form.Label>
                  <i className="fa fa-info-circle-o"></i>
                  Comment on Synonyms
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="synonyms suggestion"
                  onChange={(e) => setSynonymsSuggestion(e.target.value)}
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Control
                  type="email"
                  placeholder="Enter your email (optional)"
                  onChange={(e) => setreporterEmail(e.target.value)}
                />
              </Form.Group>
              <br />
              <Button
                variant="primary"
                onClick={(e) => {
                  sendsynonymSuggestions(e);
                }}
              >
                Submit
              </Button>
            </Form>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SynonymsMdl;

import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
// import { Button } from "react-bootstrap";
import _ from "underscore";

const qs = require("query-string");
const store = require("store");
const RecentAdvSearch = ({
  props,
  getHistoryAdv,
  setAdvSearchbox,
  prepareRecentQuery,
  setAdvSearchboxField,
}) => {
  // var search_history = getHistory(true);
  const [search_history, setSearchHistory] = useState("");
  const [selected_checkbox, setSelectedCheckbox] = useState([]);
  const [selected_dropdown, setSelectedDropdown] = useState([]);
  const [showall, setShowall] = useState(false);

  const [advsearchconditionbtn, setAdvSearchConditionbtn] = useState("AND");

  const [toggle, setToggle] = useState(false);
  const isMountedRef = useRef(true);

  // var search_history = "";
  const clearHistory = () => {
    store.remove("advanced");
    setSearchHistory("");
  };

  const handleSearchAdv = (event, criteria) => {
    var prepare_qs = prepareRecentQuery(criteria);
    store.set("key", "advanced2");
    var search_criteria = {
      criteria: prepare_qs,
      // proximity: proximity,
      advsearchfield: criteria.advsearchboxfield,
      search_type: "advanced2",
    };

    props.history.push({
      pathname: "/Searchresult",
      search:
        "?criteria=" +
        prepare_qs +
        (!_.isUndefined(criteria.advsearchboxfield)
          ? "&advsearchboxfield=" + criteria.advsearchboxfield
          : "") +
        "&search_type=advanced2",
      state: search_criteria,
    });
    event.preventDefault();
  };
  let searchChecked = [],
    searchSelected = [];
  const handleCheckbox = (event, idx) => {
    setAdvSearchboxField("");
    if (!_.isEmpty(selected_dropdown)) {
      searchSelected.push(...selected_dropdown);
    }
    if (!_.isEmpty(selected_checkbox)) searchChecked = [...selected_checkbox];
    else setSelectedCheckbox(selected_checkbox);
    // Checked
    if (event.target.checked === true) {
      searchChecked.push(idx);
      if (!_.isEmpty(selected_checkbox))
        setSelectedCheckbox([...selected_checkbox, idx]);
      else setSelectedCheckbox(searchChecked);
      //Unchecked
    } else {
      if (!_.isEmpty(searchChecked))
        searchChecked.splice(searchChecked.indexOf(idx), 1);
      if (!_.isEmpty(selected_checkbox))
        selected_checkbox.splice(searchChecked.indexOf(idx), 1);

      if (!_.isEmpty(selected_checkbox))
        setSelectedCheckbox(searchChecked, ...selected_checkbox);
      else setSelectedCheckbox(searchChecked);
    }

    createAdvQS(advsearchconditionbtn);
  };
  const handleConditionBtn = (event, condition) => {
    if (!_.isEmpty(selected_checkbox)) {
      searchChecked = [];
      searchChecked.push(...selected_checkbox);
    }

    setAdvSearchConditionbtn(condition);
    createAdvQS(condition);
    event.preventDefault();
  };
  const handleSelect = (event, idx) => {
    if (!_.isEmpty(selected_checkbox)) {
      searchChecked.push(...selected_checkbox);
    }
    // remove if already exist in state var array
    removeExistingDropDown(idx);

    if (!_.isEmpty(selected_dropdown)) {
      searchSelected = [...selected_dropdown];
    } else setSelectedDropdown(selected_dropdown);

    if (event.target.value !== "") {
      searchSelected.push({ idx: idx, condition: event.target.value });
      if (!_.isEmpty(selected_dropdown))
        setSelectedDropdown([
          ...selected_dropdown,
          { idx: idx, condition: event.target.value },
        ]);
      else setSelectedDropdown(searchSelected);
    } else if (event.target.value === "") {
      if (!_.isEmpty(searchSelected))
        searchSelected.splice(searchSelected.indexOf(idx), 1);
      if (!_.isEmpty(selected_dropdown))
        selected_dropdown.splice(searchSelected.indexOf(idx), 1);

      if (!_.isEmpty(selected_dropdown))
        setSelectedDropdown(searchSelected, ...selected_dropdown);
      else setSelectedDropdown(searchSelected);
    }
    createAdvQS();
  };
  const removeExistingDropDown = (idx) => {
    if (!_.isEmpty(selected_dropdown)) {
      selected_dropdown.filter((item, index) => {
        if (!_.isEmpty(item)) {
          if (item.idx === idx) {
            selected_dropdown.splice(index, 1);
          }
        }
      });
      setSelectedDropdown(...selected_dropdown);
    }

    return true;
  };

  const createAdvQS = (condition = "AND") => {
    let QS = "";
    let i = 0;
    searchChecked.map((idx, value) => {
      if (idx >= 0 && i > 0) {
        if (!_.isUndefined(condition)) {
          QS += " " + condition + " ";
        }
      }
      if (search_history[idx].criteria) {
        QS += searchChecked.length > 1 ? "(" : "";
        QS += search_history[idx].criteria;
        QS += searchChecked.length > 1 ? ")" : "";
      }
      i++;
    });

    setAdvSearchbox(QS);
    return QS;
  };

  /**
   * remove search item
   * @param {*} indexToRemove
   * @returns
   */
  const removeSearchItem = (indexToRemove) => {
    var existingHistoryAdv = "";
    if (!_.isUndefined(store.get("advanced"))) {
      existingHistoryAdv = getHistoryAdv().reverse();
      existingHistoryAdv.splice(indexToRemove, 1);
      store.set("advanced", existingHistoryAdv.reverse());
      setToggle(!toggle);
    }
    return true;
  };
  const displayQS = (qs) => {
    var str = "";
    var qry = "";
    if (!_.isUndefined(qs.search_type)) {
      if (!_.isUndefined(qs.criteria) && _.isString(qs.criteria)) {
        qry = qs["criteria"].split(" ");
        str +=
          "(" +
          (qs.advsearchfield == "t" && !qs.criteria.includes("title:")
            ? qry.map((el) => "title:" + el).join(" ")
            : qry.map((el) => el).join(" ")) +
          ")";
      }

      // if (qs.from_date) {
      //   str += " from_date:" + qs.from_date;
      // }
      // if (qs.to_date) {
      //   str += " to_date:" + qs.to_date;
      // }
    }
    return str;
  };
  let show_records = 30;
  const checkHistoryAtIntervals = (intervals, index = 0) => {
    if (index >= intervals.length) return;

    setTimeout(() => {
      if (!isMountedRef.current) return;

      setSearchHistory(getHistoryAdv().reverse());
      // Call the function recursively with the next interval
      checkHistoryAtIntervals(intervals, index + 1);
    }, intervals[index] * 1000);
  };
  useEffect(() => {
    setSearchHistory(getHistoryAdv().reverse());
    // (function myLoop(i) {
    //   setTimeout(function () {
    //     if (--i) myLoop(i); //  decrement i and call myLoop again if i > 0
    //     setSearchHistory(getHistoryAdv().reverse());

    //     return () => {
    //       isMountedRef.current = false;
    //     };
    //   }, 200);
    // })(10);
    // Define intervals in seconds
    const intervals = [5, 3, 5, 10, 5, 5, 5, 10, 20];

    // Start checking history at intervals
    checkHistoryAtIntervals(intervals);

    return () => {
      isMountedRef.current = false;
    };
  }, [toggle]);

  return (
    props.isPro &&
    search_history && (
      <div className="advancedsearch--search-history">
        <ul className="search-history-list">
          <li className="search-history-list--title">
            <div className="search-history-list--title--content">
              <div className="search-history-list-copy">
                <h2>Search History</h2>
                {search_history && (
                  <a
                    href="!#"
                    onClick={(e) => {
                      clearHistory();
                      e.preventDefault();
                    }}
                  >
                    Clear All
                  </a>
                )}
              </div>
              <div className="search-history-list-results">
                <h3>Results</h3>
              </div>
            </div>
            <div className="search-history-list--title--actions"></div>
          </li>

          {search_history.map((value, idx) => {
            // if (idx < show_records || showall) {
            if (idx < show_records) {
              return (
                <li
                  key={"checkboxlistitem" + idx}
                  id={"checkboxlistitem" + idx}
                  className="search-history-list--single"
                >
                  <div className="search-history-list--single--content">
                    <div className="search-history-list-copy">
                      <input
                        type="checkbox"
                        key={"chk" + idx}
                        name="chkAdv[]"
                        onChange={(e) => {
                          handleCheckbox(e, idx);
                          let elname = "checkboxlistitem" + idx;
                          if (e.target.checked) {
                            //active
                            document
                              .getElementById(elname)
                              .classList.add("active");
                          } else {
                            //inactive
                            document
                              .getElementById(elname)
                              .classList.remove("active");
                          }
                        }}
                        value={"checkboxitem" + idx}
                        id={"checkboxitem" + idx}
                      ></input>

                      <label htmlFor={"checkboxitem" + idx}>
                        {" "}
                        <p># {_.size(search_history) - idx}</p>
                        <p>{displayQS(value)} </p>{" "}
                      </label>
                    </div>
                    <div className="search-history-list-results">
                      <a
                        key={"total-count" + idx}
                        className="type-small"
                        href="#"
                        onClick={(e) => {
                          handleSearchAdv(e, value);
                          e.preventDefault();
                        }}
                      >
                        {!_.isUndefined(value.total)
                          ? value.total
                          : "Not found"}
                      </a>
                    </div>
                  </div>
                  <div className="search-history-list--single--actions">
                    <a
                      key={"deleteresults" + idx}
                      className="type-small"
                      href="#"
                      onClick={(e) => {
                        removeSearchItem(idx);
                        e.preventDefault();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"></path>
                      </svg>
                    </a>
                  </div>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
        <div className="results-list-actions">
          <a
            href="#"
            onClick={(e) => {
              // setShowall(!showall);
              e.preventDefault();
            }}
          >
            {!showall ? "Show all" : "Hide"}
          </a>
        </div>
        <div className="search-history-operator-actions">
          <ul>
            <li>Combine in a new search</li>
            <li>
              <a
                href="#"
                onClick={(e) => {
                  setAdvSearchConditionbtn("AND");
                  handleConditionBtn(e, "AND");
                }}
                className="btn"
              >
                AND
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={(e) => {
                  setAdvSearchConditionbtn("OR");
                  handleConditionBtn(e, "OR");
                }}
                className="btn"
              >
                OR
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={(e) => {
                  setAdvSearchConditionbtn("NOT");
                  handleConditionBtn(e, "NOT");
                }}
                className="btn"
              >
                NOT
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  );
};
export default RecentAdvSearch;

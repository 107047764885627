import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import _ from "underscore";
import axios from "axios";

const NHSLibkeyMdl = ({
  childInstitutions,
  handleClose,
  show,
  setInstitutionLibId,
}) => {
  const [institutionsLibOption, setInstitutionsLibOption] = useState("");
  const handleSelectLibkey = (e) => {
    var selected_institution = childInstitutions.find(
      (x) => x.id === parseInt(institutionsLibOption, 10)
    );

    if (!_.isUndefined(selected_institution)) {
      axios
        .post(
          !_.isUndefined(process.env.REACT_APP_API_URL)
            ? process.env.REACT_APP_API_URL + "/changeLibkeyInstitution"
            : "/changeLibkeyInstitution",
          {
            selected_institution: selected_institution,
          }
        )
        .then((res) => {
          if (res.status === 200 && !_.isUndefined(res.data)) {
            setInstitutionLibId(institutionsLibOption);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    handleClose(e);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="md"
      enforceFocus={false}
      data-focus="false"
    >
      <Form width="100%" onSubmit={handleSelectLibkey}>
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-book"></i> Libkey Integration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Select Institution for Libkey Access:</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                setInstitutionsLibOption(e.target.value);
              }}
            >
              <option>Select..</option>
              {childInstitutions &&
                childInstitutions.map((value, idx) => (
                  <option value={value.id} key={idx}>
                    {value.institution_name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
          <Button variant="success" onClick={handleSelectLibkey}>
            <i className="fa fa-key"></i> Select & Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NHSLibkeyMdl;

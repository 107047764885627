import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Alert } from "react-bootstrap";

import _ from "underscore";
import axios from "axios";

const GuidelinesMdl = ({ handleClose, show, publication_id }) => {
  const [score, setScore] = useState({});
  const [total, setTotal] = useState("");

  async function fetchGuidelines(id) {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Administrator/getGuidelinesScore"
          : "/Administrator/getGuidelinesScore",
        {
          params: { pub_id: id },
        }
      )
      .then((res) => {
        if (res.status === 200 && !_.isUndefined(res.data)) {
          if (!_.isUndefined(res.data.results)) {
            setScore(res.data.results);
            let t = 0;
            t += !_.isUndefined(res.data.results.methodology)
              ? parseInt(res.data.results.methodology)
              : 0;
            t += !_.isUndefined(res.data.results.evidence_grading)
              ? parseInt(res.data.results.evidence_grading)
              : 0;
            t += !_.isUndefined(res.data.results.systematic_evidence)
              ? parseInt(res.data.results.systematic_evidence)
              : 0;
            t += !_.isUndefined(res.data.results.funding)
              ? parseInt(res.data.results.funding)
              : 0;
            t += !_.isUndefined(res.data.results.conflict)
              ? parseInt(res.data.results.conflict)
              : 0;
            setTotal(t);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    if (publication_id) fetchGuidelines(publication_id);
  }, [publication_id]);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      centered
    >
      <Form width="100%">
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-info-circle"></i> Guidelines Score
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The guideline score is an attempt to classify guidelines based on
            how evidence-based we feel they are based on a 5-point scoring
            system.{" "}
            <a
              href="https://blog.tripdatabase.com/2023/05/08/introducing-our-guideline-score/"
              target="_new"
              rel="nofollow"
            >
              {" "}
              Click here for more information (including limitations)
              <i className="fa fa-external-link"></i>
            </a>
          </p>
          <p>
            Do they publish their methodology (maximum score 2)?:{" "}
            {score.methodology && <b>{score.methodology}</b>}
          </p>
          <p>
            Do they use any evidence grading (maximum score 2)?:{" "}
            {score.evidence_grading && <b>{score.evidence_grading}</b>}
          </p>
          <p>
            Do they undertake a systematic evidence search (maximum score 2)?:{" "}
            {score.systematic_evidence && <b>{score.systematic_evidence}</b>}
          </p>
          <p>
            Are they clear about funding (maximum score 1)?:{" "}
            {score.funding && <b>{score.funding}</b>}
          </p>
          <p>
            Do they mention how they handle conflict of interest (maximum score
            1)?: {score.conflict && <b>{score.conflict}</b>}
          </p>
          <p>Total Score : {total && <b>{" " + total}</b>}</p>

          <p>{score.comments && <b>{score.comments}</b>}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default GuidelinesMdl;

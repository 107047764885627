import React, { useState, useEffect } from "react";
// import { Row, Col, Alert } from "react-bootstrap";
import ErrorMdl from "../components/errorMdl";
// import "../components/assets/css/Latest.css";

const axios = require("axios");
const _ = require("underscore");
const TripAnswers = (props) => {
  const [errorshow, setErrorShow] = useState(false);
  const [hasError] = useState(false);
  const [data, setData] = useState("");
  const [searchterm, setSearchterm] = useState("");
  const [loading, setLoading] = useState(true);
  const dateFormat = require("dateformat");

  /**
   * fetchData
   * Send a call to express API for standard search
   * @param {*} keywords
   */
  async function fetchData() {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Tripanswers"
          : "/Tripanswers",
        {
          params: getParameters(),
        }
      )

      .then((res) => {
        if (res.status === 200) {
          if (!_.isUndefined(res.data)) {
            setData(res.data);
            if (_.isObject(res.data)) {
              if (!_.isUndefined(res.data.search_terms)) {
                setSearchterm(res.data.search_terms);
              }
            }
          } else {
            setData("<p>Document not found!</p>");
          }

          setLoading(false);
        } else {
          const error = new Error(res.error_message);
          // setErrors(res.error_message);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  useEffect(() => {
    document.title = "Trip Answers (Q&A) : Trip Medical Database";

    fetchData();
  }, []);
  var question = !_.isUndefined(props.match.params)
    ? props.match.params.question
    : "";
  function getParameters() {
    question = question.replace("-", " ");
    var params = {
      question: question,
    };
    return params;
  }
  const handlSubmit = (e) => {
    var search_criteria = {
      criteria: searchterm,
    };
    props.history.push({
      pathname: "/Searchresult",
      search: "?criteria=" + searchterm + "&search_type=standard",
      state: search_criteria,
    });
  };

  return (
    <main role="main" className="container-fluid main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />
      <hr></hr>
      <div className="row result-filters-row">
        {/* Sidebar */}
        <div className="col-sm-auto result-filters-container">
          <div className="filter-results">
            {/* <h6 className="text-muted">Filter Results</h6> */}
            <div className="filter-results--group">
              <div className="filter-results--group--intro">
                <h5 className="text-dark">Clinical Q&A</h5>
              </div>

              <p>
                This question and answer form part of a large corpus of over
                7,000 clinical Q&As that have been generated over the years by
                various services Trip Database have run or been involved in.
                Trip are keen to retain this collection for a historical and
                research reasons.
              </p>
            </div>
            <hr></hr>
            <div className="filter-results--group">
              <div className="filter-results--group--intro">
                <h5 className="text-dark">More results</h5>
              </div>
              <p>
                {searchterm ? (
                  //   <a
                  //     href={
                  //       "/Searchresult?search_type=standard&criteria=" +
                  //       searchterm
                  //     }
                  //   >
                  <a href="#" onClick={handlSubmit}>
                    {" "}
                    As this is more than 2 years old, click here for more
                    relevant results for {searchterm}
                  </a>
                ) : (
                  "aa"
                )}
              </p>
            </div>
            <hr></hr>
            <div className="filter-results--group">
              <div className="filter-results--group--intro">
                <h5 className="text-dark">What is trip?</h5>
              </div>
              <p>
                Trip is a clinical search engine designed to allow users to
                quickly and easily find and use high-quality research evidence
                to support their practice and/or care. Trip has been online
                since 1997 and in that time has developed into the internet’s
                premier source of evidence-based content. Our motto is ‘Find
                evidence fast’ and this is something we aim to deliver for every
                single search. As well as research evidence we also allow
                clinicians to search across other content types including
                images, videos, patient information leaflets, educational
                courses and news. For further information on Trip click on any
                of the questions/sections on the left-hand side of this page.
                But if you still have questions please contact us via
                jon.brassey@tripdatabase.com
              </p>
            </div>
          </div>
        </div>
        <div className="col results-list-container">
          <div className="results-list">
            {loading && (
              <div>
                <div className="spinner-border text-info"></div> Processing
                request.....
              </div>
            )}
            {_.isObject(data) ? (
              <div>
                <h1>
                  {!_.isUndefined(data.question_text) ? data.question_text : ""}
                </h1>
                {!_.isUndefined(data.answer_text) ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: data.answer_text }}
                  ></div>
                ) : (
                  ""
                )}
                <h4>
                  {" "}
                  {!_.isUndefined(data.answer_date) &&
                  !_.isNull(data.answer_date)
                    ? "Answered on " +
                      // dateFormat(data.answer_date, "d mmmm yyyy")
                      data.answer_date.split("T")[0]
                    : ""}
                </h4>
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: data }}></div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default TripAnswers;

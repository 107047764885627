import React, { useState, useEffect } from "react";
import _ from "underscore";
import { Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";
import Sidebar from "../../components/SidebarRegistration";
import LoginForm from "../../components/LoginForm";
import { useAuth } from "./../../context/auth";

const axios = require("axios");
const pk = "9lsoo3-4924-4kkkka-llikh2";
const store = require("store");
const jwt = require("jsonwebtoken");

const LoginAdmin = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errormessage, setError] = useState("");
  const [rememberme, setRememberme] = useState(false);

  const { setAuthTokens } = useAuth();

  const handleLogin = (event) => {
    remember(rememberme);
    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Auth/Login"
          : "/Auth/Login",
        {
          username: username,
          password: password,
          credentials: "include",
        },
        { withCredentials: true }
      )
      .catch((error) => {
        console.log(error);
        setError(error);
      })
      .then((res) => {
        if (res) {
          if (!_.isUndefined(res.status) && res.status === 200) {
            setError("");
            setAuthTokens(res.data);
            // window.location.href = window.location.origin + "/Admin";
            props.history.push({
              pathname: "/Admin",
              state: {
                withCredentials: true,
              },
              withCredentials: true,
            });
          } else {
            // const error = new Error(res.error);
            // throw error;
            setError(res.status);
          }
        }
      });

    event.preventDefault();
  };
  /**
   * remeber function
   * @param {*} flag
   */
  function remember(flag) {
    if (flag) {
      var remember_token = "";
      remember_token = jwt.sign(
        { username: username, password: password },
        pk,
        { expiresIn: "300d" }
      );
      store.set("remember", remember_token);
    } else {
      store.set("remember", null);
    }
  }
  /**
   * checkRemember me
   */
  function checkRemember() {
    var existingrememberTokens = "";
    if (!_.isUndefined(store.get("remember"))) {
      existingrememberTokens =
        !_.isNull(store.get("remember")) && store.get("remember") !== ""
          ? store.get("remember")
          : null;
      if (!_.isNull(existingrememberTokens)) {
        var decode = jwt.decode(existingrememberTokens, {
          complete: true,
        });
        if (!_.isUndefined(decode.payload)) {
          setUsername(decode.payload.username);
          setPassword(decode.payload.password);
          setRememberme(true);
        }
      } else setRememberme(false);
    } else {
      setRememberme(false);
    }
  }

  useEffect(() => {
    document.title = "Login - CMS Trip Medical Database";
    document.body.classList.remove("homepage");
    checkRemember();
    //fetchPage();
  }, []);

  return (
    <main role="main" className="main">
      <br></br>
      <Row className="mt-4 mb-4">
        <Col md={2}></Col>
        <Col md={6}>
          <div className="card">
            <div className="card-body">
              <div className="card-header mb-2">
                <h2>Login</h2>
              </div>

              <LoginForm
                logindata={{ username: username, password: password }}
                handleLogin={handleLogin}
                setUsername={setUsername}
                setPassword={setPassword}
                variant={"info"}
                setRememberme={setRememberme}
                rememberme={rememberme}
              />
              {errormessage && <h5 className="text-danger">Access Denied</h5>}
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default LoginAdmin;

import React from "react";
import BadFlag from "../../components/assets/svg/BadFlag";
const SRgraphics = ({ score, handleSR }) => {
  let str;
  switch (true) {
    case score.retracted === 1 || score.authors_score == -1:
      str = (
        <span className="trip-score--label__red  d-flex align-items-center">
          {" "}
          <BadFlag style={{ width: "10px", height: "12px" }}></BadFlag>Bad
        </span>
      );

      break;
    case (score.white_list == 1 && score.retracted !== 1) ||
      (score.assessed > 0 && score.retracted !== 1 && score.total > 7):
      str = <span className="trip-score--label__green">Appears Promising</span>;
      break;
    case score.white_list == 2 && score.retracted !== 1:
      str = <span className="text-yellow-dark">Potential Concerns</span>;
      break;
    case score.assessed === 1 &&
      score.white_list == 0 &&
      score.total >= 0 &&
      score.total <= 7:
      str = <span className="text-yellow-dark">Potential Concerns</span>;

      break;
    case score.assessed === -1 &&
      score.score_status == -1 :
      str = <span className="text-score-guage">Unable to assess</span>;

      break;
  }
  return (
    <div className="trip-score">
      <span className="trip-score--title">Review Analysis</span>
      {score.assessed == 0 ? (
        <div className="trip-score--gauge">
          <span>Not Yet Assessed</span>
        </div>
      ) : (
        <div className="trip-score--gauge">
          {/* <span>Risk of Bias:</span> */}
          {str}
        </div>
      )}
      {(score?.assessed == 1 || score?.assessed == -1) && (
        <a
          type="button"
          className="trip-score--explainer-link"
          href="!/#"
          onClick={(e) => {
            handleSR(e, score);
          }}
          target="_blank"
        >
          ?
        </a>
      )}
    </div>
  );
};
export default SRgraphics;

import React from 'react';
import { Link } from "react-router-dom";

const MainNavigation = ({ props, active_link }) => {
  return (
    <div className="desktop_navigation desktop-only">
      <ul className="navbar-nav navbar-nav__main-nav">
        <li
          className={active_link == "/About" ? "nav-item active" : "nav-item"}
        >
          <Link
            className={
              active_link == "/About" ? "nav-link fw-bold" : "nav-link"
            }
            to="/About"
            title="About"
            target="_parent"
          >
            About
          </Link>
        </li>
        <li
          className={
            active_link == "/view/evidence" ? "nav-item active" : "nav-item"
          }
        >
          <Link
            className={
              active_link == "/view/evidence" ? "nav-link fw-bold" : "nav-link"
            }
            to="/view/evidence"
            title="Trip Evidence"
            target="_parent"
          >
            Trip Evidence
          </Link>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            href="https://blog.tripdatabase.com/"
            target="_blank"
            rel="noopener noreferrer"
            title="Blog"
          >
            Blog <i className="fa fa-external-link-alt"></i>
          </a>
        </li>
        {!props.isPro && (
          <li className="nav-item">
            <Link
              className={
                active_link == "/Why" ? "nav-link fw-bold" : "nav-link"
              }
              to="/Why"
              title="Go Pro"
              target="_parent"
            >
              Go Pro
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default MainNavigation; 
import React, { useState, useEffect } from "react";
import _ from "underscore";
import { useAuth } from "./../context/auth";
import { Link } from "react-router-dom";
import { checkTokens } from "./../context/checkTokens";
import LoginMdl from "./LoginMdl";
import SignupMdl from "./SignupMdl";
import ErrorMdl from "./../components/errorMdl";

// const axios = require("axios");
// const store = require("store");

const NavigationBarMobile = (props) => {
  const [isLogin, setLogin] = useState(false);
  const [isPro, setProUser] = useState(false);
  const [loginshow, setLoginShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [signupshow, setSignupShow] = useState(false);
  const { setAuthTokens, authTokens } = useAuth();
  const handleClose = () => {
    // document.body.classList.remove("hide-account-btn");
    document.body.classList.remove("admin-page");
    document.body.classList.remove("mobile-off-canvas--is-visible");
  };
  useEffect(() => {
    var loginObj = checkTokens(authTokens);
    setProUser(loginObj.chkPro);
    setLogin(loginObj.chkLogin);
  }, [isLogin, isPro]);

  let active_link = !_.isUndefined(window.location.pathname)
    ? window.location.pathname
    : "";

  return (
    <div className="mobile-off-canvas-menu">
      <div className="mobile-off-canvas-menu--dismiss-area">
        <a
          className="dismiss-btn js-dismiss-mobile-off-canvas-menu"
          href="#"
          title="Close"
          onClick={(e) => {
            handleClose();
            e.preventDefault();
          }}
        ></a>
      </div>
      <LoginMdl
        props={props}
        setLoginShow={setLoginShow}
        setSignupShow={setSignupShow}
        setErrorShow={setErrorShow}
        setErrors={setErrors}
        show={loginshow}
      />
      <SignupMdl
        props={props}
        setLoginShow={setLoginShow}
        setSignupShow={setSignupShow}
        setErrorShow={setErrorShow}
        setErrors={setErrors}
        show={signupshow}
      />
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <div className="mobile-off-canvas-menu--dismiss">
        <a
          className="dismiss-btn js-dismiss-mobile-off-canvas-menu"
          onClick={(e) => {
            handleClose();
            e.preventDefault();
          }}
          href="#"
        >
          <i className="fa fa-times"></i>
        </a>
      </div>

      <div className="mobile-off-canvas-menu--content">
        <ul className="">
          <li className="nav-item active">
            <Link
              className={
                active_link === "/About"
                  ? "nav-link text-decoration-underline position-relative ps-3"
                  : "nav-link"
              }
              to="/About"
              title="About us"
              onClick={(e) => {
                handleClose();
              }}
            >
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={
                active_link === "/view/evidence"
                  ? "nav-link text-decoration-underline position-relative ps-3"
                  : "nav-link"
              }
              to="/view/evidence"
              title="Trip Evidence"
              onClick={(e) => {
                handleClose();
              }}
            >
              Trip Evidence
            </Link>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://blog.tripdatabase.com/"
              target="_blank"
              rel="no referrer"
              title="Blog"
              onClick={(e) => {
                handleClose();
              }}
            >
              Blog <i className="fa fa-external-link-alt"></i>
            </a>
          </li>
          {isPro && (
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/Why"
                title="Go Pro"
                onClick={(e) => {
                  handleClose();
                }}
              >
                Go Pro
              </Link>
            </li>
          )}
        </ul>

        {/* <hr /> */}
        {!isLogin ? (
          <ul className="mobile-admin-buttons">
            <li className="nav-item">
              <Link
                className={
                  active_link === "/Login" || active_link === "/Home"
                    ? "nav-link text-decoration-underline position-relative ps-3"
                    : "nav-link"
                }
                to="/Login"
                title="Login"
                onClick={(e) => {
                  setErrors("");
                  setLoginShow(!loginshow);
                  setSignupShow(false);
                  handleClose();
                  e.preventDefault();
                }}
              >
                Login
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={
                  active_link === "/Registration"
                    ? "nav-link text-decoration-underline position-relative ps-3"
                    : "nav-link btn"
                }
                onClick={(e) => {
                  setErrors("");
                  setSignupShow(!signupshow);
                  setLoginShow(false);
                  handleClose();

                  e.preventDefault();
                }}
                to="/Registration"
                title="Sign up"
              >
                Sign Up
              </Link>
            </li>
          </ul>
        ) : (
          <ul className="">
            <li className="nav-item">
              <Link
                className={
                  active_link === "/Profile"
                    ? "nav-link text-decoration-underline position-relative ps-3"
                    : "nav-link"
                }
                to="/Profile"
                title="My Account"
                onClick={(e) => {
                  handleClose();
                }}
              >
                <i className="fas fa-user-circle"></i>
                &nbsp; My Account
              </Link>
            </li>

            <li className="nav-item">
              <a
                className="nav-link"
                href="/Logout?remember=false"
                title="Logout"
                onClick={(e) => {
                  handleClose();
                }}
              >
                <i className="fa fa-power-off feather"></i> Logout
              </a>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default NavigationBarMobile;

import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Alert } from "react-bootstrap";

import _ from "underscore";
import axios from "axios";

const SRMdl = ({ handleClose, show, score }) => {
  let rdois = null,
    doi_retracted_original = null,
    doi_retracted = null;
  if (
    _.isObject(score) &&
    score?.retracted_doi &&
    score?.retracted_doi !== null
  ) {
    rdois = score?.retracted_doi.split(",");
    doi_retracted_original = rdois[0];
    doi_retracted = rdois[1];
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      centered
    >
      <Form width="100%">
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-info-circle"></i> Systematic Review Score
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>Systematic Review Score:</b> aims to help users assess the
            potential quality of a systematic review by looking at key hints of
            strength or weakness. While the score provides insight, it is not
            definitive. Our primary goal is to encourage users to approach
            systematic reviews with appropriate scepticism.{" "}
            <a
              href="https://blog.tripdatabase.com/2025/02/27/systematic-review-score-explained/"
              target="_new"
              rel="nofollow"
            >
              {" "}
              Click here for more information (including limitations)
              <i className="fa fa-external-link"></i>
            </a>
          </p>
          {score?.white_list === 0 ? (
            <div>
              <div>
                {score.assessed === 1 &&
                score.retracted !== 1 &&
                score.authors_score !== -1 &&
                score.total > 0 &&
                score.total <= 7 ? (
                  <p>
                    This systematic review has scored{" "}
                    <b>'Potential Concerns'</b> as there are a number of
                    elements that may indicate a problematic systematic review
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div>
                {score.assessed > 0 &&
                  score.retracted !== 1 &&
                  score.authors_score !== -1 &&
                  score.total > 7 && (
                    <p>
                      This systematic review has scored{" "}
                      <b>'Appears Promising'</b> as there are a number of
                      elements that indicate the systematic review has been
                      carried out well.
                    </p>
                  )}
              </div>
              <div>
                {score.assessed == -1 &&
                  score.retracted !== 1 &&
                  score.authors_score !== -1 && (
                    <p>
                      We have been unable to assess this systematic review as we
                      were unable to find enough text to score it. This might be
                      due to an absent abstract or issues with the publishers
                      website e.g. redirects.
                    </p>
                  )}
              </div>
              {score?.assessed != -1 ? (
                <div>
                  <p>
                    Age of review?:{" "}
                    {score.age_score_desc && <b>{score.age_score_desc}</b>}
                  </p>
                  <p>
                    Number of Authors?:{" "}
                    {score.authors_score_desc && (
                      <b>{score.authors_score_desc}</b>
                    )}
                  </p>
                  <p>
                    Databases Searched ?:{" "}
                    {score.databases_score_desc && (
                      <b>{score.databases_score_desc}</b>
                    )}
                  </p>
                  <p>
                    Mention of Registration?:{" "}
                    {score.mention_pros_score_desc && (
                      <b>{score.mention_pros_score_desc}</b>
                    )}
                  </p>
                  <p>
                    Mention of quality tools ?:{" "}
                    {score.mention_grade_score_desc && (
                      <b>{score.mention_grade_score_desc}</b>
                    )}
                  </p>
                </div>
              ) : (
                ""
              )}
              <div>
                {/* Retraction watch check for the article and referenced work
                (maximum score 1 Yes=1, No:0)?:{" "} */}
                {score.retracted_score == 1 || score.authors_score == -1 ? (
                  <div>
                    {score.retracted_score == 1 && (
                      <p>
                        This systematic review has been given a{" "}
                        <b>Red Flag (Bad)</b> as, irrespective of other scoring
                        elements, it contains an article that has been
                        retracted.
                      </p>
                    )}
                    {score.authors_score == -1 && (
                      <p>
                        This systematic review has been given a{" "}
                        <b>Red Flag (Bad)</b> irrespective of other scoring
                        elements, it has only one listed author.
                      </p>
                    )}
                    {doi_retracted_original && (
                      <>
                        Retraction watch check for the article and referenced
                        work?: <b>Retraction Found</b>
                        <br></br>Retracted Article (Original):
                        <a
                          href={`https://doi.org/${doi_retracted_original}`}
                          target="_blank"
                        >
                          <i className="fa fa-link"></i>{" "}
                          {doi_retracted_original}
                        </a>
                      </>
                    )}
                    {doi_retracted && doi_retracted !== "Unavailable" && (
                      <>
                        <br></br>
                        Retracted Article:{" "}
                        <a
                          href={`https://doi.org/${doi_retracted}`}
                          target="_blank"
                        >
                          <i className="fa fa-link"></i> {doi_retracted}
                        </a>
                      </>
                    )}
                    {/* {score.authors_score == -1 && (
                      <>
                        <br></br>
                        Authors: 1 author
                      </>
                    )} */}
                  </div>
                ) : (
                  <p>
                    Retraction watch check for the article and referenced work?:{" "}
                    <b>No retractions found</b>
                  </p>
                )}
              </div>
            </div>
          ) : score?.white_list === 2 ? (
            <div>
              {score.retracted_score == 1 ? (
                <p>
                  This systematic review has been given a <b>Red Flag (Bad)</b>{" "}
                  as, it contains an article that has been retracted.
                </p>
              ) : (
                <div>
                  This systematic review has scored <b>'Potential Concerns'</b>{" "}
                  as they are on a preprint server and, as such, have not been
                  peer-reviewed.
                </div>
              )}
            </div>
          ) : (
            <div>
              <p>
                <b>Trusted Source</b>
              </p>
              <div>
                {score.retracted_score == 1 ? (
                  <p>
                    <b>
                      {" "}
                      This systematic review has been given a Red Flag (Bad) as,
                      even though it is from a trusted source, it contains an
                      article that has been retracted.
                    </b>{" "}
                    {doi_retracted_original && (
                      <>
                        <br></br>Original DOI:
                        <a
                          href={`https://doi.org/${doi_retracted_original}`}
                          target="_blank"
                        >
                          {" "}
                          {doi_retracted_original}
                        </a>
                      </>
                    )}
                    {doi_retracted && doi_retracted !== "Unavailable" && (
                      <>
                        <br></br>
                        Retraction DOI:
                        <a
                          href={`https://doi.org/${doi_retracted}`}
                          target="_blank"
                        >
                          {doi_retracted}
                        </a>
                      </>
                    )}
                  </p>
                ) : (
                  <p>
                    This systematic review has scored <b>'Appears Promising'</b>{" "}
                    as it has been produced by a trusted source.
                  </p>
                )}
              </div>
            </div>
          )}
          <br></br>
          <p>
            NOTE: we recommend a full critical appraisal of the systematic
            review
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default SRMdl;

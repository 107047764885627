import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Alert } from "react-bootstrap";

import _ from "underscore";
import axios from "axios";

const BrokenlinkMdl = ({
  handleClose,
  show,
  document,
  handleReportClick,
  reporterEmail,
  setreporterEmail,
}) => {
  //   const validateEmail = (email) => {
  //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     return emailRegex.test(email);
  //   };

  return (
    <Modal
      show={show}
      // onHide={handleClose}
      onHide={(e) => {
        handleReportClick(e, {
          document_id: document.id,
          title: document.Title,
          link: document.Url,
          reporterEmail: reporterEmail,
        });
      }}
      animation={false}
      size="xl"
      centered
      className="broken-link-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Broken Link?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="flow">
        <h6>{document?.Title}</h6>
        <p>
          Thanks for letting us know. Would you like to be updated when we find
          the correct link?
        </p>
        <Form>
          <Form.Group>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              onChange={(e) => setreporterEmail(e.target.value)}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={(e) => {
              handleReportClick(e, {
                document_id: document.id,
                title: document.Title,
                link: document.Url,
                reporterEmail: reporterEmail,
              });
            }}
          >
            Submit
          </Button>{" "}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BrokenlinkMdl;

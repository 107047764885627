import React, { useState } from "react";
import _ from "underscore";
// import { Link } from "react-router-dom";
import checkCookies from "../context/checkCookies";

const CookieBar = ({ isPro }) => {
  const [cookieDipslay, setCookieDisplay] = useState(true);
  return (
    <>
      {cookieDipslay && !checkCookies.getCookies() && (
        <div className="toast-container position-fixed bottom-0 end-0 p-3">
          <div 
            className="toast show" 
            role="alert" 
            aria-live="assertive" 
            aria-atomic="true"
          >
            <div className="toast-body text-bg-primary p-4">
              <div className="type-small pb-2">
                By using this site you agree to our terms and conditions (see{" "}
                <a href="/Terms">'Terms of Use'</a>,{" "}
                <a href="/Privacy">'Privacy Policy'</a> and{" "}
                <a href="/Acceptableuse">'Acceptable Use'</a>). Our site also uses
                cookies, both essential and non-essential (see{" "}
                <a href="/Cookies">Cookie Policy</a>).
              </div>
              <div className="mt-2 pt-2 border-top">
                <button
                  className="btn btn-primary btn-sm mt-2"
                  id="rcc-confirm-button"
                  aria-label="Accept cookies"
                  onClick={(e) => {
                    setCookieDisplay(false);
                    checkCookies.setCookies(true);
                    e.preventDefault();
                  }}
                >
                  I understand
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBar;

const axios = require("axios");
const _ = require("underscore");

const PublicationPromiseOptions = (inputValue) => {
  return axios
    .get(
      !_.isUndefined(process.env.REACT_APP_API_URL)
        ? process.env.REACT_APP_API_URL +
            "/Administrator/getAllPublications?search=" +
            inputValue
        : "/Administrator/getAllPublications?search=" + inputValue,
      {
        withCredentials: true,
      }
    )

    .then((res) => {
      if (res.status === 200) {
        return res.data.lookupdata;
      } else {
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
export default PublicationPromiseOptions;

import React from "react";
import SRgraphics from "./SRgraphics";
import SRcompute from "./SRcompute";
// import GuidelinesNoGraphics from "./GuidelinesNoGraphics";
const _ = require("underscore");
const SRScore = ({ score, handleSR, category }) => {
  let scores = SRcompute(score);

  return scores && category == "Systematic Reviews" ? (
    <SRgraphics score={scores} handleSR={handleSR}></SRgraphics>
  ) : null;
};
export default SRScore;

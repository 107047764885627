import React from "react";

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14.05 14.05"
    style={{ padding: "0px" }}
  >
    <defs>
      <clipPath id="clippath">
        <path
          d="M8.27,1.5c-1.8,0-1.84-1.5-3.93-1.5-1.13,0-2.18.51-2.63.88v6.28c.64-.42,1.6-.87,2.64-.87,1.97,0,2.25,1.43,4.01,1.43,1.14,0,1.92-.71,1.92-.71V.76s-.87.74-2.01.74Z"
          clipRule="evenodd"
          fill="none"
        />
      </clipPath>
      <clipPath id="clippath-1">
        <rect y=".14" width=".86" height="12" fill="none" />
      </clipPath>
    </defs>
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <g>
      <g clipPath="url(#clippath)">
        <rect x=".71" y="-1" width="10.57" height="9.71" fill="#ed2024" />
      </g>
      <g clipPath="url(#clippath-1)">
        <rect x="-1" y="-.86" width="2.86" height="14" fill="#5f6060" />
      </g>
    </g>
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
    <rect x="-1237.86" y="-83.86" width="1440" height="3093" fill="none" />
  </svg>
);

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ErrorMdl from "../components/errorMdl";
import PageContent from "../components/PageContent";

const Cookies = (props) => {
  const [errorshow, setErrorShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [currentPage, setCurrentPage] = useState("cookie-policy");
  const [data, setData] = useState([]);

  return (
    <main role="main" className="container-fluid main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <div>
        <Helmet>
          <title>Cookies Policy : Trip Medical Database</title>
          <meta
            name="description"
            content="Cookies : Evidence-based answers for health professionals | Searching sources such as systematic reviews, clinical guidelines and RCTs"
          />
        </Helmet>
        <PageContent
          currentPage={currentPage}
          data={data}
          error={hasError}
          errorsShow={errorshow}
          setData={setData}
          setErrors={setErrors}
          setErrorShow={setErrorShow}
        />
      </div>
    </main>
  );
};

export default Cookies;

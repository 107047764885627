const _ = require("underscore");

module.exports = function SRcompute(score) {
  let databases_score = 0,
    scores,
    age_score = 0,
    authors_score = 0,
    mention_pros_score = 0,
    mention_grade_score = 0,
    white_list = 0,
    assessed = 1,
    total = 0;
  let databases_score_desc = null,
    age_score_desc = null,
    authors_score_desc = null,
    mention_pros_score_desc = null,
    mention_grade_score_desc = null;

  if (
    Array.isArray(score) &&
    score[0] &&
    !_.isUndefined(score[0].score_status)
  ) {
    if (score[0].score_status == -1) {
      assessed = -1;
    } else if (score[0].score_status == 1 || score[0].score_status == 2) {
      white_list = score[0].score_status;
    } else {
      if (score[0].age >= 0 && score[0].age <= 2) {
        age_score = 3;
        age_score_desc = "Good";
      } else if (score[0].age >= 3 && score[0].age <= 5) {
        age_score = 1;
        age_score_desc = "Ok";
      } else if (score[0].age > 5) {
        age_score = 0;
        age_score_desc = "Perhaps overdue an update";
      }

      if (score[0].authors < 2) {
        authors_score = -1;
        authors_score_desc = "Red flag";
      } else if (score[0].authors == 2) {
        authors_score = 0;
        authors_score_desc = "Bad";
      } else if (score[0].authors > 2) {
        authors_score = 2;
        authors_score_desc = "No concerns";
      }

      if (score[0].databases <= 1) {
        databases_score = 0;
        databases_score_desc = "Cause for concern";
      } else if (score[0].databases == 2) {
        databases_score = 1;
        databases_score_desc = "Cause for concern";
      } else if (score[0].databases > 2) {
        databases_score = 3;
        databases_score_desc = "No concerns";
      }

      if (score[0].mention_pros < 1) {
        mention_pros_score = 0;
        mention_pros_score_desc = "Cause for concern";
      } else if (score[0].mention_pros > 0) {
        mention_pros_score = 3;
        mention_pros_score_desc = "Good";
      }

      if (score[0].mention_grade > 0) {
        mention_grade_score = 3;
        mention_grade_score_desc = "Good";
      } else if (score[0].mention_grade < 1) {
        mention_grade_score = 0;
        mention_grade_score_desc = "Cause for concern";
      }
      // console.log(
      //   "score1::",
      //   total,
      //   "age>",
      //   age_score,
      //   score[0].age,
      //   "auht",
      //   authors_score,
      //   score[0].authors,
      //   score[0].authors > 2,
      //   "data>",
      //   databases_score,
      //   "grade>",
      //   mention_grade_score,
      //   "pros>",
      //   mention_pros_score,
      //   score
      // );
      total =
        age_score +
        authors_score +
        databases_score +
        mention_grade_score +
        mention_pros_score;
    }

    scores = {
      assessed: assessed,
      total: total,
      retracted: score[0].retracted,
      white_list: white_list,
      mention_grade_score: mention_grade_score,
      mention_pros_score: mention_pros_score,
      retracted_score: score[0].retracted,
      databases_score: databases_score,
      age_score: age_score,
      authors_score: authors_score,
      score_status: score[0].score_status,
      retracted_doi: score[0].retracted_doi,
      age_score_desc: age_score_desc,
      authors_score_desc: authors_score_desc,
      databases_score_desc: databases_score_desc,
      mention_grade_score_desc: mention_grade_score_desc,
      mention_pros_score_desc: mention_pros_score_desc,
    };
  } else {
    scores = { assessed: 0, final: "Not Yet Assessed" };
  }
  return scores;
};

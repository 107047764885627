import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useAuth } from "./../context/auth";
import {
  setQueryStringValue,
  getQueryStringValue,
} from "../components/search/queryString";
import ErrorMdl from "../components/errorMdl";
import _ from "underscore";

const axios = require("axios");
const store = require("store");

const Logout = (props) => {
  const [errorshow, setErrorShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const { authTokens, setAuthTokens } = useAuth();
  const [OALogout, setOALogout] = useState(false);
  /**
   * Logout function
   */
  async function logout(remember) {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Auth/Logout"
          : "/Auth/Logout",
        {
          withCredentials: true,
        }
      )

      .then((res) => {
        setAuthTokens("");
        if (!_.isUndefined(store.get("instution_auth_tokens"))) {
          store.set("instution_auth_tokens", { institution_auth: null });
        }
        if (!_.isUndefined(store.get("remember")) && remember) {
          store.remove("remember");
        }
        store.remove("auth_check");
        if (res.status === 200) {
          if (_.isUndefined(getQueryStringValue("OA"))) {
            props.history.push({
              pathname: "/Home",
              state: {
                withCredentials: true,
              },
            });
          } else {
            // setOALogout(true);
            window.location.href = "https://login.openathens.net/signout";
          }
        } else {
          const error = new Error(res.error_message);
          setErrors(res.error_message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    var signout_fully = !_.isUndefined(getQueryStringValue("remember"))
      ? getQueryStringValue("remember")
      : false;
    signout_fully = signout_fully == "false" ? true : false;

    logout(signout_fully);
  }, []);
  return (
    <main role="main" className="container-fluid main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <div></div>
      {OALogout && (
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text" id="prepend-oa-lgout">
              <i className="fa fa-power-off"></i>{" "}
            </span>
          </div>
          <div>
            <a
              className="btn btn-primary"
              href="https://login.openathens.net/signout"
            >
              Fully Logout from OpenAthens
            </a>{" "}
            <a className="btn btn-primary" href="/Home">
              <i className="fa fa-home"></i> Home
            </a>
          </div>
        </div>
      )}
    </main>
  );
};

export default Logout;

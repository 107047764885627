import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Import your images
import tripKey1 from "../components/assets/keys/trip_key_1.png";
import tripKey2 from "../components/assets/keys/trip_key_2.png";
import tripKey3 from "../components/assets/keys/trip_key_3.png";
import tripKey4 from "../components/assets/keys/trip_key_4.png";
import tripKey5 from "../components/assets/keys/trip_key_5.png";
import tripKey6 from "../components/assets/keys/trip_pyramids_key.png";
import tripKey7 from "../components/assets/keys/trip_key_6.png";
import tripKey8 from "../components/assets/keys/trip_key_7.png";
import tripKey9 from "../components/assets/keys/trip_key_8.png";
import tripKey10 from "../components/assets/keys/trip_key_9.png";
import tripKey11 from "../components/assets/keys/trip_key_10.png";
import tripKey12 from "../components/assets/keys/trip_key_11.png";
import tripKey13 from "../components/assets/keys/trip_key_12.png";
import tripKey14 from "../components/assets/keys/trip_key_13.png";


const Key = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <p>The new trip icons and images are explained below:</p>
        </div>
      </div>

      <div className="accordion" id="tripExplanation">
        {/* Pro Features */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <span className="me-2">Pro only Features</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey1} alt="Pro only features" />
                <p>Yellow 'Pro' flashes highlight extra content or features you obtain with a Pro subscription</p>
              </div>
            </div>
          </div>
        </div>

        {/* Ordering Results */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <span className="me-2">Ordering Results</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey2} alt="Trip status" />
                <p>Alters how Trip orders the results</p>
              </div>
            </div>
          </div>
        </div>

        {/* Document Snippets */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <span className="me-2">Document Snippets</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey3} alt="Trip dates" />
                <p>Turn on or off the document snippets</p>
              </div>
            </div>
          </div>
        </div>

        {/* Filtering Search Results */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              <span className="me-2">Filtering Search Results</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey4} alt="Trip duration" />
                <p>Filter your search by evidence type, clinical area etc.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Article Evidence Type */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
              <span className="me-2">Article Evidence Type</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey5} alt="Trip location" />
                <p>Highlights the ‘evidence type’ category for the article. this links
                  to filter on the left-hand side</p>
              </div>
            </div>
          </div>
        </div>

        {/* Pyramids */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSix">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
              <span className="me-2">Pyramids</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey6} alt="Pyramids" />
                <p>
                  A simply tool to help convey the potential robustness of the
                  evidence (for more information{" "}
                  <a href="https://blog.tripdatabase.com/2021/09/26/pyramids-are-back/">
                    click here
                  </a>
                  )
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Dead Links */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSeven">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
              <span className="me-2">Dead Links</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey7} alt="Dead links" />
                <p>
                  If you come across a broken link please use this and Trip will try
                  to fix it
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Export Results */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingEight">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
              <span className="me-2">Export Results</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey8} alt="Export Results" />
                <p>Export articles in a variety of formats</p>
              </div>
            </div>
          </div>
        </div>

        {/* Article Format / Length */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingNine">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
              <span className="me-2">Article Format / Length</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey9} alt="Article Format / Length" />
                <p>Indicates if the article is available as full-text or abstract</p>
              </div>
            </div>
          </div>
        </div>

        {/* Sharing on Twitter */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTen">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
              <span className="me-2">Sharing on Twitter</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey10} alt="Sharing on Twitter" />
                <p>Tweet the article from within Trip</p>
              </div>
            </div>
          </div>
        </div>

        {/* Bookmarks */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingEleven">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
              <span className="me-2">Bookmarks</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey11} alt="Bookmarks" />
                <p>Bookmark the article so you can view it later</p>
              </div>
            </div>
          </div>
        </div>

        {/* Related Articles */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwelve">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
              <span className="me-2">Related Articles</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseTwelve" className="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey12} alt="Related Articles" />
                <p>Related articles</p>
              </div>
            </div>
          </div>
        </div>

        {/* Risk of Bias */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThirteen">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
              <span className="me-2">Risk of Bias</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseThirteen" className="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey13} alt="Risk of Bias" />
                <p>For Randomised Controlled Trials, using RobotReviewer, we estimate the Risk of Bias for the trial</p>
              </div>
            </div>
          </div>
        </div>

        {/* Your Profile */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFourteen">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
              <span className="me-2">Your Profile (Pro Feature)</span>
              <svg className="accordion-icon" width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </h2>
          <div id="collapseFourteen" className="accordion-collapse collapse" aria-labelledby="headingFourteen" data-bs-parent="#tripExplanation">
            <div className="accordion-body">
              <div className="key-point">
                <img className="key-image border border-info" src={tripKey14} alt="Your Profile - Pro only Feature" />
                <p>Links to your profile, including bookmarks, search history, inbox and password reset</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Key;

import React from "react";
import { Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "underscore";
import { useAuth } from "../context/auth";
const store = require("store");

const SidebarRegistration = (props) => {
  const { authTokens, setAuthTokens } = useAuth();
  const isPro = !_.isUndefined(props.isPro) ? props.isPro : false;
  var isLogin = false;
  if (authTokens) isLogin = authTokens.loggedin;
  const { location } = props;
  let active_link = !_.isUndefined(location.pathname) ? location.pathname : "";
  return (
    <Row className="ml-0 mr-1" style={{ zIndex: "2" }}>
      {isLogin || isPro ? (
        <div className="container">
          <div className="admin-navbar-container">

          
          
            <nav className="navbar navbar-expand-md navbar-light bg-light border border-primary w-100" >
                <div className="container-fluid">
                <h5 className="mb-0 d-md-none">Admin menu</h5>
                <button className="navbar-toggler bg-dark-subtle" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav flex-md-column">
                    <li className={active_link == "/Inbox" ? "nav-item active" : "nav-item"}>
                      <Link className="nav-link" to="/Inbox" title="Latest">
                        Latest
                      </Link>
                    </li>
                    <li className={active_link == "/History" ? "nav-item active" : "nav-item"}>
                      <Link className="nav-link" to="/History" title="History">
                        History
                      </Link>
                    </li>
                    <li className={active_link == "/Bookmarked" ? "nav-item active" : "nav-item"}>
                      <Link className="nav-link" to="/Bookmarked" title="Bookmarked">
                        Bookmarked
                      </Link>
                    </li>
                    <li className={active_link == "/Profile" ? "nav-item active" : "nav-item"}>
                      <Link className="nav-link" to="/Profile" title="Edit Profile">
                        Edit Profile
                      </Link>
                    </li>
                    <li className={active_link == "/Subscription" ? "nav-item active" : "nav-item"}>
                      {!isPro || document.body.className !== "pro-user" ? (
                        <Link className="nav-link" to="/Subscription" title="Upgrade Pro">
                          Upgrade Pro
                        </Link>
                      ) : (
                        <Link className="nav-link" to="/Subscription" title="Renew Pro">
                          Renew Pro
                        </Link>
                      )}
                    </li>
                    <li className={active_link == "/Changepassword" ? "nav-item active" : "nav-item"}>
                      <Link className="nav-link" to="/Changepassword" title="Change password">
                        Change Password
                      </Link>
                    </li>
                    <li className={active_link == "/Logout" ? "nav-item active" : "nav-item"}>
                      <Link className="nav-link" to="/Logout?remember=false" title="Logout">
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              </nav>
          </div>
      </div>
      ) : (
        ""
      )}
    </Row>
  );
};

export default SidebarRegistration;

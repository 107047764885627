import React, { useEffect } from "react";
import { Table, Row, Col, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import _ from "underscore";
import axios from "axios";
const dateformat = require("dateformat");
const { customStyles } = require("./CMS/tableStyle");

const HistoryComponent = ({
  currentPage,
  currentPerpage,
  currentSort,
  currentTotal,
  data,
  error,
  errorshow,
  loading,
  setData,
  setErrors,
  setTotal,
  setLoading,
  setLoggedin,
  handlePerRowsChange,
  handlePageChange,
  handleSearch,
}) => {
  //console.log(publicationdata);
  async function fetchSearchHistory() {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/User/getHistory"
          : "/User/getHistory",
        {
          params: {
            page: currentPage,
            sort: currentSort,
            rows: currentPerpage,
          },
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setData(res.data.results);
          setTotal(res.data.total);
          setLoading(false);
          setLoggedin(1);
        } else {
          const error = new Error(res.message);
          setErrors(res.message);
          setLoggedin(1);
        }
      })
      .catch((error) => {
        if (!_.isEmpty(error)) {
          setErrors(error);
          // setLoggedin(0);
          setLoggedin(1);
        }
      });
  }

  useEffect(() => {
    document.body.classList.remove("homepage");
    fetchSearchHistory();
  }, [currentPage, currentPerpage, currentSort]);
  const columns = [
    {
      name: "Date",
      selector: "createdAt",
      sortable: true,
      wrap: true,
      cell: (row) => row.createdAt,
      // dateFormat(row.createdAt),
    },
    {
      name: "Search term",
      selector: "keywords",
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div>
          <a
            href="!/#"
            onClick={(e) => {
              handleSearch(e, row.criteria);
            }}
          >
            {row.keywords}
          </a>
        </div>
      ),
    },

    {
      name: "Articles Viewed",
      selector: "documents_viewed",
      sortable: false,
      cell: (row) => (
        <div>
          <div>
            {row.documents_viewed ? JSON.parse(row.documents_viewed).length : 0}
          </div>
        </div>
      ),
    },
  ];
  const HistoryExpandedComponent = ({ data }) => {
    var docs_viewed = data.documents_viewed
      ? JSON.parse(data.documents_viewed)
      : [];
    return (
      <Card variant="info" border="success">
        <Card.Body>
          <Card.Header className="text text-success">
            <h4>Articles Viewed/Starred</h4>
          </Card.Header>

          {!_.isEmpty(docs_viewed) ? (
            <Row className="mt-2">
              <Col>
                <Table>
                  <tbody>
                    {docs_viewed.map((transaction, idx) => (
                      <tr key={idx}>
                        <td>
                          <a
                            href={"/Document/" + transaction.document_id}
                            target="_blank"
                          >
                            {transaction.title
                              ? transaction.title
                              : transaction.document_id}
                          </a>{" "}
                          {transaction.activity == 1 ? (
                            <i className="fa fa-star text-warning"></i>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ) : (
            <h5 className="text-warning">No Articles viewed</h5>
          )}
        </Card.Body>
      </Card>
    );
  };
  return (
    <DataTable
      title="My Search History"
      striped={true}
      customStyles={customStyles}
      columns={columns}
      data={data}
      progressPending={loading}
      expandableRows
      highlightOnHover
      // defaultSortField="name"
      expandOnRowClicked
      expandableRowsComponent={<HistoryExpandedComponent />}
      // onSort={handleSort}
      // sortServer
      pagination
      paginationServer
      paginationTotalRows={currentTotal}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      // subHeader
      // subHeaderComponent={subHeaderComponentMemo}
    />
  );
};

export default HistoryComponent;

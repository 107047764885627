import React, { useEffect } from "react";
// import { Form, Button, Modal } from "react-bootstrap";
import _ from "underscore";
// import "react-datepicker/dist/react-datepicker.css";
// import AsyncSelect from "react-select/async";
import axios from "axios";

const PageContent = ({
  currentPage,
  data,
  error,
  errorshow,
  setData,
  setErrors,
  setErrorShow,
  clsName,
}) => {
  //console.log(publicationdata);
  async function fetchPage() {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/WPages"
          : "/WPages",
        {
          params: {
            page: currentPage,
          },
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setData(res.data.html);
        } else {
          const error = new Error(res.error);
          setData("<p>Page not found!</p>");
          // setErrors(res.error);
          //setErrorShow(true);
        }
      })
      .catch((error) => {
        setData("<p>Page not found!</p>");

        // setErrors(error.message);
        // setErrorShow(true);
      });
  }

  useEffect(() => {
    document.body.classList.remove("homepage");
    fetchPage();
  }, [currentPage]);

  return (
    <div className={clsName} dangerouslySetInnerHTML={{ __html: data }}></div>
  );
};

export default PageContent;

export const handleCategoryToggle = (categoryshow, setCategoryShow) => {
  // console.log('Before toggle - categoryshow:', categoryshow);
  // console.log('Before toggle - body classes:', document.body.classList.toString());

  // Check if the class is actually present
  const isVisible = document.body.classList.contains(
    "result-filters--is-visible"
  );

  // Sync the visual state with the categoryshow state
  if (isVisible !== categoryshow) {
    categoryshow = isVisible;
  }

  if (!categoryshow) {
    document.body.classList.add("result-filters--is-visible");
    // console.log('Adding class');
  } else {
    document.body.classList.remove("result-filters--is-visible");
    // console.log('Removing class');
  }

  setCategoryShow(!categoryshow);

  // console.log('After toggle - body classes:', document.body.classList.toString());
  // console.log('-------------------');
}; 
import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import _ from "underscore";
/**
 * Extracts the summary and the remaining text from the given text blob.
 * @param {string} text - The input text blob.
 * @returns {object} - An object containing the summary and the remaining text.
 */
function extractSummaryAndElements(text) {
  const summaryPattern =
    /Summary\s*(<b>|<strong>)?([\s\S]*?)(<\/b>|<\/strong>)?\s*$/i;
  const summaryMatch = text.match(summaryPattern);

  const summary = summaryMatch ? summaryMatch[2].trim() : "";
  const remainingText = summaryMatch
    ? text.replace(summaryPattern, "").trim()
    : text.trim();

  return { summary, remainingText };
}
function convertMarkdownToHtml(text) {
  // Replace ### with <h3> tags
  text = text.replace(/^###\s*(.+)$/gm, "<strong>$1</strong>");

  // Replace ** with <b> tags
  text = text.replace(/\*\*(.+?)\*\*/g, "<b>$1</b>");

  // Replace \n with <br> tags
  text = text.replace(/\n/g, "<br>");

  return text;
}
const RobAnalysisMdl = ({ handleClose, show, id }) => {
  const [gptAnswer, setgptAnswer] = useState("");
  const [gptSummary, setgptSummary] = useState("");

  const [showAnalysis, setShowAnalysis] = useState(false);
  const [showExtended, setShowExtended] = useState(false);

  const fetchGptAnalysis = async (article_id) => {
    setgptAnswer("");
    setgptSummary("");
    await axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/gptAnalysis"
          : "/gptAnalysis",
        {
          params: { id: article_id },
          withCredentials: true,
        }
        // {
        //   withCredentials: true,
        // }
      )

      .then(async (res) => {
        if (res.status === 200) {
          if (res?.data?.answer) {
            let htmltext = extractSummaryAndElements(res.data.answer);
            let summary = convertMarkdownToHtml(htmltext.summary);
            let extended_answer = convertMarkdownToHtml(htmltext.remainingText);
            setgptSummary(summary);
            setgptAnswer(extended_answer);
            setShowAnalysis(false);
          }
        }
      })
      .catch((error) => {
        return null;
      });
    // e.preventDefault();
  };
  useEffect(() => {
    setgptAnswer("");
    setgptSummary("");
    if (!_.isUndefined(id)) {
      setShowAnalysis(true);
      setShowExtended(false);
      fetchGptAnalysis(id);
    } else {
      setgptAnswer("Invalid Request!");
    }
  }, [id]);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      centered
    >
      <Form width="100%">
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-info-circle"></i> Analysis +
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>The Analysis + </p> */}
          <div className="mt-4">
            {/* <Button
              variant="btn"
              onClick={() => {
                if (!_.isUndefined(id)) {
                  setShowAnalysis(true);
                  setShowExtended(false);
                  fetchGptAnalysis(id);
                } else {
                  setgptAnswer("Invalid Request!");
                }
              }}
            >
              Click here to generate Analysis
            </Button> */}
            {/* {!showAnalysis && <div>Show response..</div>} */}
            {showAnalysis ? (
              <div className="pull-center">
                <Spinner animation="border" size="sm" variant="success" />{" "}
                Processing analysis..
              </div>
            ) : (
              <span>
                {gptSummary ? (
                  <div>
                    <b>Summary:</b>
                    <div dangerouslySetInnerHTML={{ __html: gptSummary }} />
                    <Button
                      variant="btn"
                      onClick={() => {
                        setShowExtended(!showExtended);
                      }}
                    >
                      {!showExtended ? "More" : "Less"}..
                    </Button>
                  </div>
                ) : (
                  // <div>Summary not found....{gptSummary}</div>
                  ""
                )}
                {showExtended ? (
                  <div dangerouslySetInnerHTML={{ __html: gptAnswer }} />
                ) : (
                  // <div>Extended not found....{gptAnswer}</div>
                  ""
                )}
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default RobAnalysisMdl;

import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {
  Nav,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Button,
} from "react-bootstrap";
import { checkTokensStorage } from "../../context/checkTokens";
import KeyModal from './KeyModal';
import MindMapModal from './MindMapModal';
import { MindMap } from './MindMap';
import { handleCategoryToggle } from '../../utils/categoryUtils';

const ResultActions = ({
  isPro,
  sort,
  sortSelection,
  setSnippetShow,
  snippetshow,
  setExportShow,
  setProNotification,
  exportshow,
  isdesktop = true,
  categoryshow,
  setCategoryShow,
  rows,
  setResultRows,
  criteria
}) => {

  var current_selection = "TripRank",
    default_active = "r";
  var loginObj = checkTokensStorage();
  const [showKeyModal, setShowKeyModal] = useState(false);
  const [showMindMapModal, setShowMindMapModal] = useState(false);
  const [showMindMap,setShowMindMap] = useState(false);
  const options = [
    { value: "r", label: "TripRank" },
    { value: "t", label: "Relevance" },
    { value: "y", label: "Date" },
  ];

  if (sort) {
    current_selection =
      options.find((option) => option.value === sort)?.label || "TripRank";
    default_active = sort;
  }
  const handleKeyClick = (e) => {
    e.preventDefault();
    setShowKeyModal(true);
  };
  const handleMindMapClick = (e) => {
    e.preventDefault();
    setShowMindMapModal(true);
  };
  // return isdesktop === true ? (
  return (
    <>
      <div className="results-actions">
        {/* Filter results toggle for mobile only */}
        <div className="filter-results-toggle">
          <Button
            className="navbar-btn js-sidebar-toggle navbar-toggler navbar-dark"
            type="button"
            title="Filter results"
            onClick={() => handleCategoryToggle(categoryshow, setCategoryShow)}
            data-toggle
          >
            <span className="navbar-toggler-icon"></span>
            Filter Results
          </Button>
        </div>

        {isdesktop ? (
          <>
            <div className="results-actions--left-group">
              <div className="dropdown">
                <button
                  type="button"
                  className="btn dropdown-toggle d-inline-flex align-items-center"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  Display options{" "}
                  <svg
                    className="ms-1 me-0"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.50001 0H9.50001L10.0939 2.37548C10.7276 2.6115 11.3107 2.95155 11.8223 3.37488L14.1782 2.70096L15.6782 5.29904L13.9173 7.00166C13.9717 7.32634 14 7.65987 14 8C14 8.34013 13.9717 8.67366 13.9173 8.99834L15.6782 10.701L14.1782 13.299L11.8223 12.6251C11.3107 13.0484 10.7276 13.3885 10.0939 13.6245L9.50001 16H6.50001L5.90614 13.6245C5.27242 13.3885 4.68934 13.0484 4.17768 12.6251L1.82181 13.299L0.321808 10.701L2.08269 8.99834C2.02831 8.67366 2.00001 8.34013 2.00001 8C2.00001 7.65987 2.02831 7.32634 2.08269 7.00166L0.321808 5.29904L1.82181 2.70096L4.17768 3.37488C4.68934 2.95155 5.27241 2.6115 5.90614 2.37548L6.50001 0ZM8.00001 10C9.10458 10 10 9.10457 10 8C10 6.89543 9.10458 6 8.00001 6C6.89544 6 6.00001 6.89543 6.00001 8C6.00001 9.10457 6.89544 10 8.00001 10Z"
                      fill="#000000"
                    />
                  </svg>
                </button>
                <div
                  className="dropdown-menu p-4"
                  style={{ minWidth: "240px" }}
                >
                  <div className="mb-3 d-inline-flex align-items-center justify-content-between w-100">
                    {/* Order By */}
                    <span>Order by: </span>
                    <Nav
                      activeKey={sort || "r"}
                      onSelect={(selectedKey) => sortSelection(selectedKey)}
                    >
                      <DropdownButton
                        as={ButtonGroup}
                        id="sort-nav-dropdown"
                        variant="null"
                        title={`${current_selection}`}
                      >
                        <Dropdown.Item eventKey="r">TripRank</Dropdown.Item>
                        <Dropdown.Item eventKey="t">Relevance</Dropdown.Item>
                        <Dropdown.Item eventKey="y">Date</Dropdown.Item>
                        {/* <Dropdown.Item eventKey="v12m">Popularity</Dropdown.Item> */}
                      </DropdownButton>
                    </Nav>
                  </div>
                  <div className="mb-3 d-inline-flex align-items-center justify-content-between w-100">
                    <span>Per page:</span>
                    <Nav
                      activeKey="20"
                      onSelect={(selectedKey) => setResultRows(selectedKey)}
                    >
                      <DropdownButton
                        as={ButtonGroup}
                        id="sort-nav-dropdown 2"
                        variant="null"
                        title={rows}
                      >
                        <Dropdown.Item eventKey="20">20</Dropdown.Item>
                        <Dropdown.Item eventKey="50">50</Dropdown.Item>
                        <Dropdown.Item eventKey="100">100</Dropdown.Item>
                      </DropdownButton>
                    </Nav>
                  </div>
                  <div className="mb-0 d-inline-flex align-items-center justify-content-between w-100">
                    {/* <div className="show-snippets-wrapper"> */}
                    <span>Show Snippets</span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={snippetshow}
                        value="1"
                        onChange={(e) => setSnippetShow(!snippetshow)}
                      />
                      <span className="switch--slide-switch round"></span>
                    </label>
                    {/* </div> */}
                  </div>
                </div>
              </div>

              <Link
                to="#"
                className="type-small"
                title="results page key"
                onClick={handleKeyClick}
              >
                User Guide
              </Link>
            </div>

            <div className="results-actions--right-group">
              {/* Mind Map Toggle */}
              <div className="btn-group">
                <button
                  type="button"
                  className="btn d-inline-flex align-items-center button-mindmap-toggle"
                  onClick={() => setShowMindMap(!showMindMap)}
                >
                  Mind Map <span className="ms-2 pro-label">New</span>
                </button>
                <button
                  type="button"
                  className="btn dropdown-toggle-split button-mindmap-toggle"
                  style={{ marginLeft: "1px" }}
                  onClick={handleMindMapClick}
                >
                  ?<span className="visually-hidden">Mind Map Explained</span>
                </button>
              </div>

              <div className="d-inline-flex align-items-center justify-content-between">
                <Button
                  className={`btn ${exportshow ? "active" : ""}`}
                  variant="outline-light"
                  onClick={() => {
                    setExportShow(!exportshow);
                    setProNotification(isPro ? false : true);
                    // Add or remove class from results list container
                    const resultsContainer = document.querySelector(
                      ".results-list-container"
                    );
                    if (resultsContainer) {
                      if (!exportshow) {
                        resultsContainer.classList.add("show-export");
                      } else {
                        resultsContainer.classList.remove("show-export");
                      }
                    }
                  }}
                >
                  Export Articles{" "}
                  {exportshow && <i className="fas fa-check ms-2"></i>}
                </Button>
              </div>

              {/* institution label */}
              {loginObj.chkInstitution.institution_name && (
                <div>
                  <i className="fa fa-university"></i>{" "}
                  {loginObj.chkInstitution.institution_name}
                  {/* <Link to="/Logout">
                    <i className="fa fa-on-off"></i> Logout?
                  </Link> */}
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="dropdown">
              <button
                type="button"
                className="btn dropdown-toggle d-inline-flex align-items-center"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                Display options{" "}
                <svg
                  className="ms-1 me-0"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.50001 0H9.50001L10.0939 2.37548C10.7276 2.6115 11.3107 2.95155 11.8223 3.37488L14.1782 2.70096L15.6782 5.29904L13.9173 7.00166C13.9717 7.32634 14 7.65987 14 8C14 8.34013 13.9717 8.67366 13.9173 8.99834L15.6782 10.701L14.1782 13.299L11.8223 12.6251C11.3107 13.0484 10.7276 13.3885 10.0939 13.6245L9.50001 16H6.50001L5.90614 13.6245C5.27242 13.3885 4.68934 13.0484 4.17768 12.6251L1.82181 13.299L0.321808 10.701L2.08269 8.99834C2.02831 8.67366 2.00001 8.34013 2.00001 8C2.00001 7.65987 2.02831 7.32634 2.08269 7.00166L0.321808 5.29904L1.82181 2.70096L4.17768 3.37488C4.68934 2.95155 5.27241 2.6115 5.90614 2.37548L6.50001 0ZM8.00001 10C9.10458 10 10 9.10457 10 8C10 6.89543 9.10458 6 8.00001 6C6.89544 6 6.00001 6.89543 6.00001 8C6.00001 9.10457 6.89544 10 8.00001 10Z"
                    fill="#000000"
                  />
                </svg>
              </button>
              <div className="dropdown-menu p-4" style={{ minWidth: "240px" }}>
                <div className="mb-3 d-inline-flex align-items-center justify-content-between w-100">
                  {/* Order By */}
                  <span>Order by: </span>
                  <Nav
                    activeKey={sort || "r"}
                    onSelect={(selectedKey) => sortSelection(selectedKey)}
                  >
                    <DropdownButton
                      as={ButtonGroup}
                      id="sort-nav-dropdown"
                      variant="null"
                      title={`${current_selection}`}
                    >
                      <Dropdown.Item eventKey="r">TripRank</Dropdown.Item>
                      <Dropdown.Item eventKey="t">Relevance</Dropdown.Item>
                      <Dropdown.Item eventKey="y">Date</Dropdown.Item>
                      {/* <Dropdown.Item eventKey="v12m">Popularity</Dropdown.Item> */}
                    </DropdownButton>
                  </Nav>
                </div>
                <div className="mb-3 d-inline-flex align-items-center justify-content-between w-100">
                  <span>Per page:</span>
                  <Nav
                    activeKey="20"
                    onSelect={(selectedKey) => setResultRows(selectedKey)}
                  >
                    <DropdownButton
                      as={ButtonGroup}
                      id="sort-nav-dropdown 2"
                      variant="null"
                      title={rows}
                    >
                      <Dropdown.Item eventKey="20">20</Dropdown.Item>
                      <Dropdown.Item eventKey="50">50</Dropdown.Item>
                      <Dropdown.Item eventKey="100">100</Dropdown.Item>
                    </DropdownButton>
                  </Nav>
                </div>
                <div className="mb-0 d-inline-flex align-items-center justify-content-between w-100">
                  {/* <div className="show-snippets-wrapper"> */}
                  <span>Show Snippets</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={snippetshow}
                      value="1"
                      onChange={(e) => setSnippetShow(!snippetshow)}
                    />
                    <span className="switch--slide-switch round"></span>
                  </label>
                  {/* </div> */}
                </div>
              </div>
            </div>

            <Link
              to="#"
              className="type-small"
              title="results page key"
              onClick={handleKeyClick}
            >
              User Guide
            </Link>

            {/* Mind Map Toggle */}
            <div className="btn-group">
              <button
                type="button"
                className="btn d-inline-flex align-items-center"
                onClick={() => setShowMindMap(!showMindMap)}
              >
                Mind Map <span className="ms-2 pro-label">New</span>
              </button>
              <button
                type="button"
                className="btn dropdown-toggle-split"
                style={{ marginLeft: "1px" }}
                onClick={handleMindMapClick}
              >
                ?<span className="visually-hidden">Mind Map Explained</span>
              </button>
            </div>

            <div className="d-inline-flex align-items-center justify-content-between">
              <Button
                className={`btn ${exportshow ? "active" : ""}`}
                variant="outline-light"
                onClick={() => {
                  setExportShow(!exportshow);
                  setProNotification(isPro ? false : true);
                  // Add or remove class from results list container
                  const resultsContainer = document.querySelector(
                    ".results-list-container"
                  );
                  if (resultsContainer) {
                    if (!exportshow) {
                      resultsContainer.classList.add("show-export");
                    } else {
                      resultsContainer.classList.remove("show-export");
                    }
                  }
                }}
              >
                Export Articles{" "}
                {exportshow && <i className="fas fa-check ms-2"></i>}
              </Button>
            </div>

            {/* institution label */}
            {loginObj.chkInstitution.institution_name && (
              <div>
                <i className="fa fa-university"></i>{" "}
                {loginObj.chkInstitution.institution_name}
              </div>
            )}
          </>
        )}
      </div>

      <KeyModal show={showKeyModal} onHide={() => setShowKeyModal(false)} />
      <MindMapModal
        show={showMindMapModal}
        onHide={() => setShowMindMapModal(false)}
      />
      {showMindMap && (
        <MindMap keywords={criteria} onClose={() => setShowMindMap(false)} />
      )}
    </>
  );
};

export default ResultActions;
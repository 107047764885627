import React, { useState, useEffect } from "react";
import _ from "underscore";
import { Row, Col } from "react-bootstrap";
// import Sidebar from "../components/SidebarRegistration";
import {
  setQueryStringValue,
  getQueryStringValue,
} from "../components/search/queryString";
const axios = require("axios");
const store = require("store");

const OALogin = (props) => {
  const [wayfayless_uri, setWayfayless] = useState("");
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);

  /**
   * on page load/update
   */
  const getURL = async () => {
    axios
      .get("/api/oalogin")
      .then((res) => {
        console.log(res);
        if (res) {
          if (!_.isUndefined(res.status) && res.status === 200) {
            setWayfayless(res.data.wayfless_uri);
            window.location.href = res.data.wayfless_uri;
          } else {
            //todo setError;
            setErrors({
              message: "An unknown error occured, contact Trip Admin",
            });
            setErrorShow(true);
          }
        } else {
          // const error = new Error(res.error);
          setErrors({
            message: "An unknown error occured, contact Trip Admin",
          });
          setErrorShow(true);
        }
        //callback for the response
      })
      .catch((error) => {
        setErrors({ message: "An unknown error occured, contact Trip Admin" });
        setErrorShow(true);
      });
  };
  useEffect(() => {
    document.title = "Login - Trip Medical Database";
    document.body.classList.remove("homepage");
    if (getQueryStringValue("target_link_uri"))
      store.set("target_link_uri", getQueryStringValue("target_link_uri"));
    else store.remove("target_link_uri");
    getURL();
  }, []);
  return (
    <main role="main" className="main mt-2 container-fluid">
      {/* <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} /> */}

      <Row></Row>
      <Row>
        <Col md="3">{/* <Sidebar {...props} /> */}</Col>
        <Col md="6">
          <Row>
            <Col>
              <div className="spinner-border spinner-border-md text-warning"></div>
              &nbsp;&nbsp;Processing .....
              {wayfayless_uri && (
                <a href={wayfayless_uri}>
                  Click here if redirect is taking too long!!
                </a>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default OALogin;

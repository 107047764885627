import React, { useState } from "react";
import { Modal, Row, Col, Button, Alert, Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import UpgradeTable from "../components/personal/UpgradeTable";
import PriceList from "../components/personal/PriceList";
import Sidebar from "../components/SidebarRegistration";
import { Helmet } from "react-helmet";

import _ from "underscore";
const axios = require("axios");
// const store = require("store");

const Upgrade = (props) => {
  const [hasError, setErrors] = useState(false);
  const [hasSuccess, setSuccess] = useState(false);
  const [show, setShow] = useState(false);

  const handleModal = (e) => {
    setShow(true);
    e.preventDefault();
  };

  return (
    <main role="main" className="main mt-2 container-fluid">
      <Row></Row>
      <Row>
        <Col md="3">
          <Sidebar {...props} />
          <Helmet>
            <meta
              name="description"
              content="Evidence-based answers for health professionals | Searching sources such as systematic reviews, clinical guidelines and RCTs"
            />
          </Helmet>
        </Col>
        <Col md="9">
          <Row>
            <Col>
              <Card>
                <Card.Header as="h4">
                  You're one step away from becoming a Pro user
                </Card.Header>
                <Card.Body>
                  <blockquote className="blockquote mb-0">
                    <p>
                      The Trip Pro offering takes an already wonderful – and
                      free – version of Trip and makes it even better. With more
                      content, more functionality, no adverts and more, it
                      really makes the Trip experience even better.
                      Institutional and personal subscriptions are available (
                      <Card.Link
                        href="!#"
                        className="text-success"
                        onClick={handleModal}
                        title="click here to see Institutional price list"
                      >
                        click here to see Institutional price list
                      </Card.Link>{" "}
                      {/* <Button variant="primary" onClick={() => setShow(true)}>
                        Custom Width Modal
                      </Button> */}
                      ). The table below highlights the main differences between
                      the various offerings.
                    </p>{" "}
                    <br />
                    <b>
                      Institutional Subscription (VAT, where applicable, will
                      apply)
                    </b>
                    <br />
                    <p>
                      {" "}
                      If your institution has not already purchased an upgrade,
                      you can find out pricing details here, prices start at
                      $215 per annum. To set this up or ask any further
                      questions please get in touch via our ‘Contact Us‘ page.
                    </p>
                    <br />
                    <p>
                      <b>Personal Subscription</b> The annual subscription cost
                      is $55 (US Dollars). If applicable, Trip Database will pay
                      VAT at your home countries rate . If you are VAT
                      registered you may claim this back.{" "}
                    </p>
                    <br></br>
                    <p>
                      Any questions then please get in touch via our ‘Contact
                      Us‘ page. You can purchase your own professional/private
                      subscription by payment card, and this will take effect
                      IMMEDIATELY:
                    </p>
                  </blockquote>
                  <Button variant="primary">Contact Us</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Alert variant="info">
                <Alert.Heading>
                  Please,
                  <Alert.Link href="/Login">Login</Alert.Link> before paying, so
                  we can credit your account
                </Alert.Heading>{" "}
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col>
              <UpgradeTable></UpgradeTable>
            </Col>
          </Row>
        </Col>
      </Row>
      <PriceList setShow={setShow} show={show}></PriceList>
    </main>
  );
};

export default Upgrade;
